import React from "react";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";

const DialogTransferirEstoque = ({
  ShowModalTransferecia,
  setShowModalTransferecia,
  TransfereciaEstoque,
  SelectedDoEstoque,
  setSelectedDoEstoque,
  SelectedParaEstoque,
  setSelectedParaEstoque,
  SelectedProdutoTransferecia,
  setSelectedProdutoTransferecia,
  ListaEmpresas,
  ListaProdutos,
  GetListaProdutos
}) => (
  <Dialog
    header="Transferir Estoque"
    visible={ShowModalTransferecia}
    modal={false}
    style={{ width: "50vw" }}
    onHide={() => setShowModalTransferecia(false)}
  >
    <form className="row" onSubmit={TransfereciaEstoque}>
      <div className="col-12">
        <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-3">
          <div className="w-100">
            <label htmlFor="doEstoque" className="form-label">
              Origem
            </label>
            <Dropdown
              id="doEstoque"
              className="w-100"
              value={SelectedDoEstoque}
              onChange={(e) => {
                GetListaProdutos(e.value.code);
                setSelectedDoEstoque(e.value);
              }}
              options={ListaEmpresas}
              optionLabel="name"
              placeholder="Selecione um estoque"
            />
          </div>
          <div>
            <i className="bi bi-arrow-right" />
          </div>
          <div className="w-100">
            <label htmlFor="paraEstoque" className="form-label">
              Destino
            </label>
            <Dropdown
              id="paraEstoque"
              className="w-100"
              value={SelectedParaEstoque}
              onChange={(e) => setSelectedParaEstoque(e.value)}
              options={ListaEmpresas}
              optionLabel="name"
              placeholder="Selecione um estoque"
            />
          </div>
        </div>
      </div>
      <div className="col-12">
        <label htmlFor="produtos" className="form-label">
          Nome
        </label>
        <MultiSelect
          id="produtos"
          value={SelectedProdutoTransferecia}
          onChange={(e) => setSelectedProdutoTransferecia(e.value)}
          options={ListaProdutos}
          optionLabel="name"
          placeholder="Selecione produtos"
          maxSelectedLabels={3}
          className="w-100"
        />
      </div>
      <div className="col-12 mt-3">
        <DataTable
          value={SelectedProdutoTransferecia}
          stripedRows
          paginator
          rows={10}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="Nenhum produto encontrado."
          className="small-table"
        >
          <Column field="code" header="Código" />
          <Column field="name" header="Nome" />
          <Column
            field="quantidade"
            header="Quantidade"
            body={(rowData) => (
              <InputNumber
                value={rowData.quantidade}
                onChange={(e) => {
                  const updatedProducts = SelectedProdutoTransferecia.map(
                    (product) =>
                      product.code === rowData.code
                        ? { ...product, quantidade: e.value }
                        : product
                  );
                  setSelectedProdutoTransferecia(updatedProducts);
                }}
              />
            )}
          />
        </DataTable>
      </div>
      <div className="col-md-12 mt-3">
        <div className="w-100 d-flex flex-row-reverse">
          <Button
            type="submit"
            label="Transferir"
            className="btn btn-primary"
          />
        </div>
      </div>
    </form>
  </Dialog>
);

export default DialogTransferirEstoque;
