import { useState, useEffect } from "react";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";

import Api from "utils/Api";

import "./statusFiliais.css";

const ListaStatusFiliais = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();

  const [Lista, setLista] = useState([]);
  const [duplicados, setDuplicados] = useState([]);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [dataAberturaRange, setDataAberturaRange] = useState(null);
  const [viewMode, setViewMode] = useState("table"); // "table" ou "cards"
  const [modalVisible, setModalVisible] = useState(false); // Controle do modal
  const [filteredLista, setFilteredLista] = useState([]);
  const [maxDataAbertura, setMaxDataAbertura] = useState("");

  const handleSearch = (searchValue) => {
    setGlobalFilterValue(searchValue.trim()); // Remover espaços extras
    const filtered = Lista.filter((item) => {
      const searchLower = searchValue.toLowerCase();
      return (
        item.operador?.toLowerCase().includes(searchLower) ||
        item.codigoLojaAtual?.toLowerCase().includes(searchLower)
      );
    });
    setFilteredLista(filtered);
  };

  const fetchEmpresas = async () => {
    try {
      const resposta = await Requicicao.Get({
        endpoint: "/Empresa/ObterTodosVendedor",
        config: auth.GetHeaders()
      });

      if (resposta.codigoEstadoHttp === 200) {
        const empresas = resposta.objetoResposta;

        // Mapeia as empresas para determinar o operador primeiro
        const empresasComOperador = empresas.map((empresa) => {
          const { matrizId } = empresa;

          // Busca o operador na matriz, se aplicável
          let operadorAtual = empresa.operador || "N/A";
          if ((operadorAtual === "N/A" || !operadorAtual) && matrizId) {
            const matriz = empresas.find((e) => e.id === matrizId);
            operadorAtual = matriz ? matriz.operador : "N/A";
          }

          return {
            ...empresa,
            operador: operadorAtual
          };
        });

        const empresasApenasFilial = empresasComOperador.filter((empresa) => {
          // Verifica se a empresa possui um `matrizId` válido
          const matriz = empresasComOperador.find(
            (e) => e.id === empresa.matrizId
          );
          return matriz !== undefined; // Inclui apenas empresas com matriz
        });

        // Filtra as empresas com base no código da loja, após determinar o operador
        return empresasApenasFilial
          .filter(
            (empresa) =>
              empresa.codigoLojaAtual && empresa.codigoLojaAtual !== "N/A"
          )
          .map((empresa) => ({
            id: empresa.id,
            codigoLojaAtual: empresa.codigoLojaAtual,
            operador: empresa.operador,
            nomeFantasia: empresa.pessoa.apelidoOuNomeFantasia,
            razaoSocial: empresa.pessoa.nomeCompletoOuRazaoSocial,
            cnpj: empresa.pessoa.cpfOuCnpj
          }));
      }

      return [];
    } catch (error) {
      console.error("Erro ao buscar empresas:", error);
      return [];
    }
  };

  const fetchStatusFiliais = async () => {
    try {
      const resposta = await Requicicao.Get({
        endpoint: "/AberturaFechamentoCaixa/ObterStatusFiliais",
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        return resposta.objetoResposta;
      }
      return [];
    } catch (error) {
      console.error("Erro ao buscar status das filiais:", error);
      return [];
    }
  };

  const fetchUltimaVenda = async () => {
    try {
      const resposta = await Requicicao.Get({
        endpoint: "/Venda/ObterDetalhesUltimaVenda",
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        return resposta.objetoResposta;
      }
      return [];
    } catch (error) {
      console.error("Erro ao buscar última venda:", error);
      return [];
    }
  };

  const normalizeDate = (date) => {
    const normalized = new Date(date);
    normalized.setHours(0, 0, 0, 0); // Define hora, minuto, segundo e milissegundo para zero
    return normalized;
  };

  const StateDataPage = async () => {
    try {
      setLoading(true);

      const [empresas, statusFiliais, ultimaVenda] = await Promise.all([
        fetchEmpresas(),
        fetchStatusFiliais(),
        fetchUltimaVenda()
      ]);

      // Identificar códigos duplicados
      const codigoCounts = empresas.reduce((acc, empresa) => {
        acc[empresa.codigoLojaAtual] = (acc[empresa.codigoLojaAtual] || 0) + 1;
        return acc;
      }, {});

      const duplicadosLista = empresas.filter(
        (empresa) => codigoCounts[empresa.codigoLojaAtual] > 1
      );

      setDuplicados(duplicadosLista);

      // Filtrar para encontrar a maior data de abertura, normalizando a data
      const maiorDataAbertura = statusFiliais
        .filter((item) => item.dataUltimaAbertura)
        .map((item) => normalizeDate(item.dataUltimaAbertura))
        .reduce((max, current) => (current > max ? current : max), new Date(0));

      setMaxDataAbertura(maiorDataAbertura.toLocaleDateString("pt-BR"));

      // Processa os dados para combinar empresas, status e vendas
      const mergedData = empresas.map((empresa) => {
        const status = statusFiliais.find(
          (filial) => filial.codigoLoja === empresa.codigoLojaAtual
        );

        const venda = ultimaVenda.find(
          (v) => v.codigoLoja === empresa.codigoLojaAtual
        );

        const dataUltimaAbertura = status?.dataUltimaAbertura
          ? normalizeDate(status.dataUltimaAbertura)
          : null;

        const caixaAberto =
          dataUltimaAbertura &&
          dataUltimaAbertura.getTime() === maiorDataAbertura.getTime();

        return {
          ...empresa,
          dataUltimaAbertura: status?.dataUltimaAbertura || null,
          dataUltimoFechamento: status?.dataUltimoFechamento || null,
          caixaAberto, // Define o status com base na lógica
          ultimaVenda: venda
            ? {
                numeroSerieCupom: venda.numeroSerieCupom,
                numeroVenda: venda.numeroVenda,
                dataCriacao: venda.dataCriacao
              }
            : null
        };
      });

      const sortedLista = [...mergedData].sort((a, b) => {
        if (a.caixaAberto === b.caixaAberto) {
          return 0;
        }
        return a.caixaAberto ? 1 : -1; // Fechados primeiro
      });

      setLista(sortedLista);
      setFilteredLista(sortedLista);

      if (maiorDataAbertura) {
        setDataAberturaRange([maiorDataAbertura, maiorDataAbertura]);
        setFilters((prevFilters) => ({
          ...prevFilters,
          dataUltimaAbertura: {
            value: [maiorDataAbertura],
            matchMode: "between"
          }
        }));
      }
    } catch (error) {
      console.error("Erro ao carregar os dados:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString || dateString === "N/A") return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit"
    });
  };

  const rowClass = (rowData) =>
    rowData.caixaAberto ? "bg-success text-white" : "bg-danger text-white";

  const renderCards = () => (
    <div id="cards-aberturas">
      {filteredLista.map((item) => (
        <div
          key={item.id}
          className={`card ${item.caixaAberto ? "card-open" : "card-closed"}`}
        >
          <h2>{item.codigoLojaAtual}</h2>
          <p>
            <strong>Operador:</strong> {item.operador}
          </p>
          <p>
            <strong>CNPJ:</strong> {item.cnpj}
          </p>
          <p>
            <strong>Data de Abertura:</strong>{" "}
            {formatDate(item.dataUltimaAbertura)}
          </p>
          <p>
            <strong>Data de Fechamento:</strong>{" "}
            {formatDate(item.dataUltimoFechamento)}
          </p>
          <p>
            <strong>Status:</strong> {item.caixaAberto ? "Aberto" : "Fechado"}
          </p>
          <hr />
          <p>
            <strong>Dados última Venda</strong>
          </p>
          <p>
            <strong>Data:</strong>{" "}
            {item.ultimaVenda?.dataCriacao
              ? formatDate(item.ultimaVenda.dataCriacao)
              : "N/A"}
          </p>
          <p>
            <strong>Série:</strong>{" "}
            {item.ultimaVenda?.numeroSerieCupom || "N/A"}
          </p>
          <p>
            <strong>Número:</strong> {item.ultimaVenda?.numeroVenda || "N/A"}
          </p>
          <p>
            <strong>GUID:</strong> {item.id}
          </p>
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    if (auth.isAuthenticated()) {
      StateDataPage();
    }
  }, [auth]);

  return (
    <div className="card p-3">
      {duplicados.length > 0 && (
        <div className="mb-3">
          <Message
            severity="warn"
            text="Códigos duplicados encontrados. Clique para detalhes."
            style={{ cursor: "pointer" }}
            onClick={() => setModalVisible(true)}
          />
        </div>
      )}
      <Dialog
        visible={modalVisible}
        style={{ width: "50vw" }}
        header="Códigos Duplicados"
        modal
        onHide={() => setModalVisible(false)}
      >
        <ul>
          {duplicados.map((dup) => (
            <li key={dup.codigoLojaAtual}>
              {dup.codigoLojaAtual} - {dup.nomeFantasia}
            </li>
          ))}
        </ul>
      </Dialog>
      <div className="d-flex justify-content-between mb-3">
        <div>
          <h5>Lojas abertas em ({maxDataAbertura})</h5>
          <span className="me-3">Pesquisa:</span>
          <InputText
            value={globalFilterValue}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Pesquisa Global"
          />
        </div>
        <Button
          label={viewMode === "table" ? "Ver como Cards" : "Ver como Tabela"}
          icon={viewMode === "table" ? "pi pi-th-large" : "pi pi-table"}
          onClick={() => setViewMode(viewMode === "table" ? "cards" : "table")}
          className="mb-3"
        />
      </div>
      {viewMode === "table" ? (
        <DataTable
          value={filteredLista}
          stripedRows
          paginator
          rows={500}
          rowClassName={rowClass}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="Nenhuma empresa encontrada."
          globalFilterFields={["nomeFantasia", "razaoSocial", "cnpj"]}
          filters={filters}
        >
          <Column field="operador" header="Operador" sortable />
          <Column field="codigoLojaAtual" header="Código" sortable />
          <Column field="cnpj" header="CNPJ" sortable />
          <Column
            field="dataUltimaAbertura"
            header="Data de Abertura"
            body={(rowData) => formatDate(rowData.dataUltimaAbertura)}
            sortable
          />
          <Column
            field="dataUltimoFechamento"
            header="Data de Fechamento"
            body={(rowData) => formatDate(rowData.dataUltimoFechamento)}
            sortable
          />
          <Column
            field="caixaAberto"
            header="Status"
            body={(rowData) => (rowData.caixaAberto ? "Aberto" : "Fechado")}
          />
        </DataTable>
      ) : (
        renderCards()
      )}
    </div>
  );
};

export default ListaStatusFiliais;
