import { createContext, useContext, useState, useEffect, useMemo } from "react";

import PropTypes from "prop-types";

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth deve ser utilizado dentro de um AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  // const router = useRouter();
  const [user, setUser] = useState(null); // Pode conter os detalhes do usuário logado

  // Verifica se o usuário está autenticado (por exemplo, verifica o token)
  const isAuthenticated = () => !!user?.token; // Verifica se o token existe
  const GetAccessLevel = () => user.acesso;
  const UserNome = () => user.pessoa.apelidoOuNomeFantasia;

  /**
   * Verifica se há pelo menos um item na lista de requisitos que está na lista de acesso do usuário.
   * @param {array} requisitos - Lista a ser verificada.
   * @returns {boolean} - Lista de requisitos a serem verificados.
   * @throws {Error} - Lança um erro se 'requisitos' não for uma lista ou contiver elementos que não sejam números.
   */
  const VerificarAccess = (requisitos) => {
    // Verifica se é um array
    if (!Array.isArray(requisitos)) {
      throw new Error("O parâmetro 'requisitos' deve ser uma lista.");
    }

    // Verifica se todos os elementos da lista são números
    const todosSaoNumeros = requisitos.every(
      (item) => typeof item === "number"
    );

    if (!todosSaoNumeros) {
      throw new Error(
        "Todos os itens na lista 'requisitos' devem ser números."
      );
    }

    const acesso = GetAccessLevel();

    if (acesso && Array.isArray(acesso)) {
      // Verifica se algum item dos requisitos está na lista de acesso.
      return requisitos.some((requisito) => acesso.includes(requisito));
    }

    return false;
  };

  // Função para fazer login (pode chamar sua API de login aqui)
  const login = (userData) => {
    setUser({
      ...userData,
      acesso: userData.permissoes.map((item) => item.permissaoId)
    });
    localStorage.setItem("useId", userData.id);
    localStorage.setItem(
      "acesso",
      JSON.stringify(userData.permissoes.map((item) => item.permissaoId))
    );
    localStorage.setItem("token", userData.token);
    localStorage.setItem("nomeFantasia", userData.pessoa.apelidoOuNomeFantasia);
    localStorage.setItem("nome", userData.pessoa.nomeCompletoOuRazaoSocial);
  };

  // Função para fazer logout
  const logout = () => {
    localStorage.removeItem("useId");
    localStorage.removeItem("acesso");
    localStorage.removeItem("token");
    localStorage.removeItem("nomeFantasia");
    localStorage.removeItem("nome");
    setUser(null);
  };

  const GetHeaders = (contentType) => {
    if (contentType === "only-authentication") {
      return {
        headers: {
          Authorization: `Bearer ${user?.token}`
        }
      };
    }

    if (contentType !== undefined) {
      return {
        headers: {
          Authorization: `Bearer ${user?.token}`,
          "Content-Type": contentType
        }
      };
    }

    return {
      headers: {
        Authorization: `Bearer ${user?.token}`,
        "Content-Type": "application/json"
      }
    };
  };

  const RotaRestrida = () => {
    try {
      const currentPath = window.location.pathname;
      if (currentPath.includes("/app")) {
        logout();
      }
    } catch (error) {
      console.error("RotasRestritas:", error);
    }
  };

  useEffect(() => {
    const VerificarAutenticacao = async () => {
      try {
        if (!isAuthenticated()) {
          const token = localStorage.getItem("token");

          if (token) {
            const acesso = JSON.parse(localStorage.getItem("acesso"));
            const apelidoOuNomeFantasia = localStorage.getItem("nomeFantasia");
            const nomeCompletoOuRazaoSocial = localStorage.getItem("nome");
            const useId = localStorage.getItem("useId");

            const data = {
              acesso,
              id: useId,
              token,
              pessoa: {
                apelidoOuNomeFantasia,
                nomeCompletoOuRazaoSocial
              }
            };

            setUser(data);
          } else {
            RotaRestrida();
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    VerificarAutenticacao();
  }, []);

  const value = useMemo(
    () => ({
      user,
      isAuthenticated,
      login,
      logout,
      GetHeaders,
      UserNome,
      GetAccessLevel,
      VerificarAccess
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired
};
