import { useState, useEffect } from "react";

import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";

import GraficoHistoricoAdmissaoDesligado from "components/GraficoHistoricoAdmissaoDesligado";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
// import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";

const AllInfo = () => {
  const auth = useAuth();
  // const notify = useNotification();
  const { setLoading } = useLoading();
  const Requicicao = new Api();

  const { StatusFuncionario } = MaskEnum;

  const [ListaFunci, setListaFunci] = useState([]);
  const [ListaEmpre, setListaEmpre] = useState([]);

  const [SelectedMatri, setSelectedMatri] = useState([]);
  const [SelectedFilias, setSelectedFilias] = useState([]);

  const [DataGrafico, setDataGrafico] = useState([]);

  const FazerGrafico = (fitro) => {
    const CalcularStatusPorMes = (data) => {
      const resultado = {};

      const startOfMonth = (date) => {
        const d = new Date(date);
        return new Date(d.getFullYear(), d.getMonth(), 1);
      };

      const endOfMonth = (date) => {
        const d = new Date(date);
        return new Date(d.getFullYear(), d.getMonth() + 1, 0);
      };

      const addMonths = (date, months) => {
        const d = new Date(date);
        d.setMonth(d.getMonth() + months);
        return d;
      };

      data.forEach((funcionario) => {
        const { statuses } = funcionario;

        statuses.forEach(({ status, dataInicio, dataFim }) => {
          let current = startOfMonth(dataInicio);
          const end = dataFim ? endOfMonth(dataFim) : endOfMonth(new Date());

          while (current <= end) {
            const monthKey = `${current
              .toISOString()
              .slice(0, 10)} - ${endOfMonth(current)
              .toISOString()
              .slice(0, 10)}`;

            if (!resultado[monthKey]) {
              resultado[monthKey] = [];
            }

            const existingStatus = resultado[monthKey].find(
              (item) =>
                item.texto ===
                StatusFuncionario.find((s) => s.value === Number(status)).label
            );

            if (existingStatus) {
              existingStatus.valor += 1;
            } else {
              resultado[monthKey].push({
                texto: StatusFuncionario.find((s) => s.value === Number(status))
                  .label,
                valor: 1
              });
            }

            current = addMonths(current, 1); // Avança para o próximo mês
          }
        });
      });

      return resultado;
    };

    const FormatarResultado = (dados) =>
      Object.entries(dados).map(([mes, contagem]) => ({
        mes,
        contagem
      }));

    try {
      const lista = ListaFunci.map((item) => ({
        ...item,
        statuses: item.statuses.filter(
          (stat) =>
            stat.dataInicio !== "0001-01-01T00:00:00" &&
            stat.dataFim !== "0001-01-01T00:00:00" &&
            stat.status !== 1 &&
            stat.status !== 4 &&
            stat.status !== 5 &&
            stat.status !== 6
        )
      }));

      const resultado = CalcularStatusPorMes(
        lista.filter((f) => fitro.includes(f.empresaId))
      );
      setDataGrafico(FormatarResultado(resultado));
    } catch (error) {
      console.error(error);
    }
  };

  const AtulizarEmrepsasIds = () => {
    try {
      setLoading(true);
      setDataGrafico([]);
      const tudo = [...SelectedMatri, ...SelectedFilias];

      // 1. Extraia todos os IDs dos pais e filhos, lidando com null/undefined
      const ids = tudo.flatMap((obj) => [
        obj.id, // Adiciona o ID do pai
        ...(obj.filiais?.map((f) => f.id) || []) // Verifica se `filhos` existe antes de mapear
      ]);

      // 2. Remova duplicados
      const idsUnicos = [...new Set(ids)];

      setTimeout(() => {
        FazerGrafico(idsUnicos);
        setLoading(false);
      }, 100);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (SelectedMatri != null) {
      setSelectedFilias(SelectedMatri.map((m) => m.filiais).flat());
    }
  }, [SelectedMatri]);

  useEffect(() => {
    const GetFuncionarios = async () => {
      try {
        setLoading(true);
        const data = await Requicicao.Get({
          endpoint: "/Funcionarios/AllInfo",
          config: auth.GetHeaders()
        });
        if (data.codigoEstadoHttp === 200) setListaFunci(data.objetoResposta);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const GetEmpresas = async () => {
      try {
        setLoading(true);
        const resposta = await Requicicao.Get({
          endpoint: "/Empresa/RH/ObterTodos",
          config: auth.GetHeaders()
        });
        if (resposta.codigoEstadoHttp === 200) {
          const Vazaio = "N/A";

          const lista = resposta.objetoResposta.map((element) => ({
            id: element.id,
            nome: element.pessoa.nomeCompletoOuRazaoSocial || Vazaio,
            nomeFantasia: element.pessoa.apelidoOuNomeFantasia || Vazaio,
            cnpj: element.pessoa.cpfOuCnpj || Vazaio,
            ehMatriz: element.ehMatriz,
            matrizId: element.ehMatriz || Vazaio,
            codigoLojaAtual: element.codigoLojaAtual,
            operador: element.operador || Vazaio,
            filiais: resposta.objetoResposta
              .filter((filial) => filial.matrizId === element.id)
              .map((filial) => ({
                id: filial.id,
                nome: filial.pessoa.nomeCompletoOuRazaoSocial,
                nomeFantasia: filial.pessoa.apelidoOuNomeFantasia,
                cnpj: filial.pessoa.cpfOuCnpj,
                codigoLojaAtual: filial.codigoLojaAtual,
                operador: filial.operador || Vazaio
              }))
          }));
          setListaEmpre(lista.filter((empresa) => empresa.ehMatriz));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const StateDataPage = async () => {
      await GetFuncionarios();
      await GetEmpresas();
    };

    if (auth.isAuthenticated()) StateDataPage();
  }, [auth]);

  const countryOptionModel = (option) => (
    <div className="d-flex align-items-center">
      <div style={{ maxWidth: "500px", width: "max-content" }}>
        <p className="m-0 text-wrap">
          <b>Nome:</b> {option.nome}
        </p>
        <p className="m-0 text-wrap">
          <b>CNPJ:</b> {option.cnpj}
        </p>
        <p className="m-0 text-wrap">
          <b>Codigo:</b> {option.codigoLojaAtual}
        </p>
        <p className="m-0 text-wrap">
          <b>Operador:</b> {option.operador}
        </p>
      </div>
    </div>
  );

  const childOptionModel = (option) => (
    <div
      className="d-flex align-items-center"
      style={{ maxWidth: "500px", width: "max-content" }}
    >
      <div>
        <p className="m-0 text-wrap">
          <b>Nome:</b> {option.nomeFantasia}
        </p>
        <p className="m-0 text-wrap">
          <b>CNPJ:</b> {option.cnpj}
        </p>
        <p className="m-0 text-wrap">
          <b>Codigo:</b> {option.codigoLojaAtual}
        </p>
        <p className="m-0 text-wrap">
          <b>Operador:</b> {option.operador}
        </p>
      </div>
    </div>
  );

  return (
    <div className="card p-3">
      <div className="row g-3">
        <div className="col-md-4">
          <label>Empresas</label>
          <MultiSelect
            value={SelectedMatri}
            options={ListaEmpre}
            optionLabel="nome"
            itemTemplate={countryOptionModel}
            onChange={(e) => setSelectedMatri(e.value)}
            filter
            className="w-100"
            maxSelectedLabels={2}
          />
        </div>
        <div className="col-md-4">
          <label>Lojas</label>
          <MultiSelect
            value={SelectedFilias}
            options={ListaEmpre}
            optionLabel="nome"
            optionGroupLabel="nome"
            optionGroupChildren="filiais"
            itemTemplate={childOptionModel}
            onChange={(e) => setSelectedFilias(e.value)}
            filter
            className="w-100"
            maxSelectedLabels={2}
          />
        </div>
        <div className="col-md-4">
          <div className="d-flex gap-3 justify-content-end align-items-end flex-wrap w-100 h-100">
            <Button
              className="btn"
              type="button"
              label="Limpar filtro"
              onClick={() => {
                setSelectedMatri([]);
                setSelectedFilias([]);
              }}
            />
            <Button
              className="btn"
              type="button"
              label="Atualizar"
              onClick={AtulizarEmrepsasIds}
            />
          </div>
        </div>
      </div>
      <div>
        <GraficoHistoricoAdmissaoDesligado DataGrafico={DataGrafico} />
      </div>
    </div>
  );
};

export default AllInfo;
