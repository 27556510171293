import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet
} from "react-router-dom";

import Layout from "components/Layout";

import { LoadingProvider } from "context/LoadingContext";
import { NotificationProvider } from "context/NotificationContext";

import Barra from "pages/barra";
import CriarConta from "pages/criarConta";
import CriarEmpresa from "pages/criarEmpresa";
import CriarGrupoProduto from "pages/criarGrupoProduto";
import CriarOperador from "pages/criarOperador";
import CriarPontoVenda from "pages/criarPontoVenda";
import CriarPruduto from "pages/criarPruduto";
import CriarVendedor from "pages/criarVendedor";
import EditarContas from "pages/editarContas";
import EditarEmpresa from "pages/editarEmpresa";
import EditarGrupoProduto from "pages/editarGrupoProduto";
import EditarOperador from "pages/editarOperador";
import EditarPontoVenda from "pages/editarPontoVenda";
import EditarPruduto from "pages/editarPruduto";
import EditarVendedor from "pages/editarVendedor";
import FazerInventario from "pages/fazerInventario";
import Home from "pages/home";
import Implantacao from "pages/Implantacao";
import ImportarProduto from "pages/importarProdutos";
import InfoUser from "pages/infoUser";
import ListaBaseConhecimento from "pages/listaBaseConhecimento";
import ListaConfiguracoesEnvioXML from "pages/listaConfiguracoesEnvioXML";
import FormConfiguracaoEnvioXML from "pages/listaConfiguracoesEnvioXML/formConfiguracaoEnvioXML";
import ListaHistoricoEnvioXML from "pages/listaConfiguracoesEnvioXML/listaHistoricoEnvioXML";
import ListaContas from "pages/listaContas";
import ListaEmpresas from "pages/listaEmpresas";
import RelatorioImplantacaoVendas from "pages/listaEmpresasSemVenda";
import ListaEstoque from "pages/listaEstoque";
import ListaFilial from "pages/listaFilial";
import ListaGrupoProduto from "pages/listaGrupoProduto";
import ListarHealthCheck from "pages/listaHealthCheck";
import ListaOperadores from "pages/listaOperadores";
import ListaPontoVenda from "pages/listaPontoVenda";
import ListaProduto from "pages/listaProduto";
import LojasSemVenda from "pages/listarLojasSemVenda";
import ListaTransicaoLojas from "pages/listarTransicaoLojas";
import ListaStatusFiliais from "pages/listaStatusFiliais";
import ListaUsuario from "pages/listaUsuario";
import ListaVemdedor from "pages/listaVemdedor";
import ListaVendasConsolidadas from "pages/listaVenda";
import ListaVendasConsolidadasPorVendedor from "pages/listaVendaPorVendedor";
import ListaVendasProdutos from "pages/listaVendaProduto";
import Login from "pages/login";
import MeusDados from "pages/meusDados";
import Notifications from "pages/Notifications";
import AllInfo from "pages/RH/allInfo";
import AcessoNovaContratacao from "pages/RH/contratacao";
import CartaProposta from "pages/RH/contratacao/cartaProposta";
import FormularioFinalizacao from "pages/RH/contratacao/formularioFinalizacao";
import ReenvioAquivos from "pages/RH/contratacao/reenvioAquivos";
import VisualizarDadosFuncionario from "pages/RH/contratacao/visualizarFuncionario";
import DocumentoAnual from "pages/RH/documentoAnual";
import DocumentoMensal from "pages/RH/documentoMensal";
import ConviteFuncionario from "pages/RH/formConvite";
import FormFuncionario from "pages/RH/formFuncionario";
import IndicadoresRH from "pages/RH/Indicadores";
import ListaDocumentosFuncionario from "pages/RH/listaDocumentosFuncionario";
import ListaEmpresasRH from "pages/RH/listaEmpresa";
import ListaFuncionario from "pages/RH/listaFuncionario";
import ListaFuncionarioContratadosAlocados from "pages/RH/listaFuncionarioContratadosAlocados";
import MeusDocs from "pages/RH/meusDocs";
import UpDocumentoEmpresa from "pages/RH/upDocumentoEmpresa";
import SeusDados from "pages/seusDados/inedex";
import TrocaSenha from "pages/trocaSenha";

import Error404 from "./modules/Error404";
import PrivateRoute from "./modules/PrivateRoute";

const MainRouter = () => {
  const routeConfig = createBrowserRouter([
    { path: "*", element: <Error404 /> },
    { path: "/", element: <Navigate to="/login" /> },
    { path: "/login", element: <Login /> },
    {
      path: "boasVindas",
      element: (
        <LoadingProvider>
          <NotificationProvider>
            <Outlet />
          </NotificationProvider>
        </LoadingProvider>
      ),
      children: [
        {
          index: true,
          element: <AcessoNovaContratacao />
        },
        {
          path: "aprovarCartaProposta",
          element: <CartaProposta />
        },
        {
          path: "finalizarCadastro",
          element: <FormularioFinalizacao />
        },
        {
          path: "dados",
          element: <VisualizarDadosFuncionario />
        },
        {
          path: "reenvio-aquivos",
          element: <ReenvioAquivos />
        }
      ]
    },
    {
      path: "/app",
      element: <PrivateRoute element={Outlet} />,
      children: [
        {
          path: "info-user",
          element: (
            <Layout NomePaginaAtual="Informações do funcionários">
              <InfoUser />
            </Layout>
          )
        },
        {
          index: true,
          element: (
            <Layout NomePaginaAtual="Página Inicial">
              <Home />
            </Layout>
          )
        },
        {
          path: "implantacao",
          element: (
            <Layout NomePaginaAtual="Implantações">
              <Implantacao />
            </Layout>
          )
        },
        {
          path: "conta",
          children: [
            {
              path: "dados",
              element: (
                <Layout NomePaginaAtual="Menus Dados">
                  <MeusDados />
                </Layout>
              )
            },
            {
              path: "meus-docs",
              element: (
                <Layout NomePaginaAtual="Meus Documentos">
                  <MeusDocs />
                </Layout>
              )
            },
            {
              path: "troca-senha",
              element: (
                <Layout NomePaginaAtual="Troca de senha">
                  <TrocaSenha />
                </Layout>
              )
            }
          ]
        },
        {
          path: "rh",
          children: [
            { index: true, element: <Navigate to="/app/rh/empresas" /> },
            {
              path: "indicadores",
              element: (
                <Layout NomePaginaAtual="Indicadores RH">
                  <IndicadoresRH />
                </Layout>
              )
            },
            {
              path: "indicadores/all-info",
              element: (
                <Layout NomePaginaAtual="Visão Geral">
                  <AllInfo />
                </Layout>
              )
            },
            {
              path: "documento",
              children: [
                { index: true, element: <Navigate to="/app/rh/empresas" /> },
                {
                  path: "anual/new",
                  element: (
                    <Layout NomePaginaAtual="Novo Documento">
                      <UpDocumentoEmpresa />
                    </Layout>
                  )
                },
                {
                  path: "mensal/new",
                  element: (
                    <Layout NomePaginaAtual="Novo Documento">
                      <UpDocumentoEmpresa />
                    </Layout>
                  )
                },
                {
                  path: "anual",
                  element: (
                    <Layout NomePaginaAtual="Documento Anual">
                      <DocumentoAnual />
                    </Layout>
                  )
                },
                {
                  path: "mensal",
                  element: (
                    <Layout NomePaginaAtual="Documento Mensal">
                      <DocumentoMensal />
                    </Layout>
                  )
                }
              ]
            },
            {
              path: "empresas",
              element: (
                <Layout NomePaginaAtual="Empresas">
                  <ListaEmpresasRH />
                </Layout>
              )
            },
            {
              path: "funcionarios",
              children: [
                {
                  index: true,
                  element: (
                    <Layout NomePaginaAtual="Lista funcionarios">
                      <ListaFuncionario />
                    </Layout>
                  )
                },
                {
                  path: "up-arquivo",
                  element: (
                    <Layout NomePaginaAtual="Documentos do funcionarios">
                      <ListaDocumentosFuncionario />
                    </Layout>
                  )
                },
                {
                  path: "editar",
                  element: <Navigate to="/app/rh/empresas" />
                },
                {
                  path: "contratados",
                  element: (
                    <Layout NomePaginaAtual="Funcionários Contratados">
                      <ListaFuncionarioContratadosAlocados />
                    </Layout>
                  )
                },
                {
                  path: "alocados",
                  element: (
                    <Layout NomePaginaAtual="Funcionários Alocados">
                      <ListaFuncionarioContratadosAlocados />
                    </Layout>
                  )
                },
                {
                  path: "new/:empresaId?",
                  element: (
                    <Layout NomePaginaAtual="Criar Funcionário">
                      <FormFuncionario />
                    </Layout>
                  )
                },
                {
                  path: "convite/:empresaId?",
                  element: (
                    <Layout NomePaginaAtual="Criar Convite">
                      <ConviteFuncionario />
                    </Layout>
                  )
                },
                {
                  path: "editar/:funcionarioId",
                  element: (
                    <Layout NomePaginaAtual="Editar Funcionário">
                      <FormFuncionario />
                    </Layout>
                  )
                }
              ]
            }
          ]
        },
        {
          path: "relatorioImplantacaoVendas",
          element: (
            <Layout NomePaginaAtual="Relatório de Implantações">
              <RelatorioImplantacaoVendas />
            </Layout>
          )
        },
        {
          path: "vendas",
          element: (
            <Layout NomePaginaAtual="Vendas">
              <ListaVendasConsolidadas />
            </Layout>
          )
        },
        {
          path: "vendasVendador",
          element: (
            <Layout NomePaginaAtual="Vendas Por Vendedor">
              <ListaVendasConsolidadasPorVendedor />
            </Layout>
          )
        },
        {
          path: "vendasPorProduto",
          element: (
            <Layout NomePaginaAtual="Vendas Por Produto">
              <ListaVendasProdutos />
            </Layout>
          )
        },
        {
          path: "healthCheck",
          element: (
            <Layout NomePaginaAtual="Health Check">
              <ListarHealthCheck />
            </Layout>
          )
        },
        {
          path: "basesConhecimento",
          element: (
            <Layout NomePaginaAtual="Base Conhecimento">
              <ListaBaseConhecimento />
            </Layout>
          )
        },
        {
          path: "conta",
          children: [
            {
              index: true,
              element: (
                <Layout NomePaginaAtual="Contas">
                  <ListaContas />
                </Layout>
              )
            },
            {
              path: "new",
              element: (
                <Layout NomePaginaAtual="Nova Conta">
                  <CriarConta />
                </Layout>
              )
            },
            {
              path: "editar",
              element: (
                <Layout NomePaginaAtual="Editar Conta">
                  <EditarContas />
                </Layout>
              )
            }
          ]
        },
        {
          path: "relatorio",
          children: [
            {
              path: "lojasTransicao",
              element: (
                <Layout NomePaginaAtual="Transição Lojas">
                  <ListaTransicaoLojas />
                </Layout>
              )
            },
            {
              path: "statusfiliais",
              element: (
                <Layout NomePaginaAtual="Status Filiais">
                  <ListaStatusFiliais />
                </Layout>
              )
            },
            {
              path: "filial",
              element: (
                <Layout NomePaginaAtual="Visão Resumo">
                  <ListaFilial />
                </Layout>
              )
            },
            {
              path: "lojasSemVenda",
              element: (
                <Layout NomePaginaAtual="Loja sem Venda">
                  <LojasSemVenda />
                </Layout>
              )
            }
          ]
        },
        {
          path: "seusdados",
          element: (
            <Layout NomePaginaAtual="Seus Dados">
              <SeusDados />
            </Layout>
          )
        },
        {
          path: "notifications",
          element: (
            <Layout NomePaginaAtual="Notificação">
              <Notifications />
            </Layout>
          )
        },
        {
          path: "usuario",
          element: (
            <Layout NomePaginaAtual="Usuários">
              <ListaUsuario />
            </Layout>
          )
        },
        {
          path: "inventario",
          element: (
            <Layout NomePaginaAtual="Inventário">
              <FazerInventario />
            </Layout>
          )
        },
        {
          path: "estoque",
          element: (
            <Layout NomePaginaAtual="Estoque">
              <ListaEstoque />
            </Layout>
          )
        },
        {
          path: "empresa",
          children: [
            {
              index: true,
              element: (
                <Layout NomePaginaAtual="Balcões">
                  <ListaEmpresas />
                </Layout>
              )
            },
            {
              path: "new",
              element: (
                <Layout NomePaginaAtual="Novo Balcão">
                  <CriarEmpresa />
                </Layout>
              )
            },
            {
              path: "editar",
              element: (
                <Layout NomePaginaAtual="Editar Balcão">
                  <EditarEmpresa />
                </Layout>
              )
            }
          ]
        },
        {
          path: "configuracaoXML",
          children: [
            {
              index: true,
              element: (
                <Layout NomePaginaAtual="Configuração XML">
                  <ListaConfiguracoesEnvioXML />
                </Layout>
              )
            },
            {
              path: "historico",
              element: (
                <Layout NomePaginaAtual="Histórico Envio XML">
                  <ListaHistoricoEnvioXML />
                </Layout>
              )
            },
            {
              path: "new",
              element: (
                <Layout NomePaginaAtual="Nova Configuração">
                  <FormConfiguracaoEnvioXML />
                </Layout>
              )
            },
            {
              path: "edit/:id",
              element: (
                <Layout NomePaginaAtual="Editar Configuração">
                  <FormConfiguracaoEnvioXML />
                </Layout>
              )
            }
          ]
        },
        {
          path: "operador",
          children: [
            {
              index: true,
              element: (
                <Layout NomePaginaAtual="Operadores">
                  <ListaOperadores />
                </Layout>
              )
            },
            {
              path: "new",
              element: (
                <Layout NomePaginaAtual="Criar Operador">
                  <CriarOperador />
                </Layout>
              )
            },
            {
              path: "editar",
              element: (
                <Layout NomePaginaAtual="Editar Operador">
                  <EditarOperador />
                </Layout>
              )
            }
          ]
        },
        {
          path: "vendedor",
          children: [
            {
              index: true,
              element: (
                <Layout NomePaginaAtual="Vendedores">
                  <ListaVemdedor />
                </Layout>
              )
            },
            {
              path: "new",
              element: (
                <Layout NomePaginaAtual="Criar Vendedor">
                  <CriarVendedor />
                </Layout>
              )
            },
            {
              path: "editar",
              element: (
                <Layout NomePaginaAtual="Editar Vendedor">
                  <EditarVendedor />
                </Layout>
              )
            }
          ]
        },
        {
          path: "pontoVenda",
          children: [
            {
              index: true,
              element: (
                <Layout NomePaginaAtual="Loja">
                  <ListaPontoVenda />
                </Layout>
              )
            },
            {
              path: "new",
              element: (
                <Layout NomePaginaAtual="Criar Loja">
                  <CriarPontoVenda />
                </Layout>
              )
            },
            {
              path: "editar",
              element: (
                <Layout NomePaginaAtual="Editar Loja">
                  <EditarPontoVenda />
                </Layout>
              )
            }
          ]
        },
        {
          path: "produto",
          children: [
            {
              index: true,
              element: (
                <Layout NomePaginaAtual="Produtos">
                  <ListaProduto />
                </Layout>
              )
            },
            {
              index: true,
              path: "importar",
              element: (
                <Layout NomePaginaAtual="Importação de Produtos">
                  <ImportarProduto />
                </Layout>
              )
            },
            {
              path: "new",
              element: (
                <Layout NomePaginaAtual="Criar Produto">
                  <CriarPruduto />
                </Layout>
              )
            },
            {
              path: "editar",
              element: (
                <Layout NomePaginaAtual="Editar Produto">
                  <EditarPruduto />
                </Layout>
              )
            },
            {
              path: "codigoDeBarra",
              element: <Barra />
            }
          ]
        },
        {
          path: "grupo",
          children: [
            {
              index: true,
              element: (
                <Layout NomePaginaAtual="Grupos de Produtos">
                  <ListaGrupoProduto />
                </Layout>
              )
            },
            {
              path: "new",
              element: (
                <Layout NomePaginaAtual="Criar Grupo de Produto">
                  <CriarGrupoProduto />
                </Layout>
              )
            },
            {
              path: "editar",
              element: (
                <Layout NomePaginaAtual="Editar Grupo de Produto">
                  <EditarGrupoProduto />
                </Layout>
              )
            }
          ]
        }
      ]
    }
  ]);

  return <RouterProvider router={routeConfig} />;
};

export default MainRouter;
