import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import ApexCharts from "components/Apexcharts";

import MaskUtil from "utils/MaskUtil";

const GraficoHistoricoAdmissaoDesligado = ({ DataGrafico }) => {
  const [Data, setData] = useState([]);

  const [Lebels, setLebels] = useState([]);

  // Combinar "Desligado" e "Desligamento"
  const CombinarDesligados = (lista) =>
    lista.map((item) => {
      // Filtrar itens relevantes
      const outros = item.contagem.filter(
        (c) => c.texto !== "Desligado" && c.texto !== "Desligamento"
      );
      const somaDesligados = item.contagem
        .filter((c) => c.texto === "Desligado" || c.texto === "Desligamento")
        .reduce((soma, c) => soma + c.valor, 0);

      // Adicionar o novo valor combinado
      if (somaDesligados > 0) {
        outros.push({ texto: "Desligados", valor: somaDesligados });
      }

      return { ...item, contagem: outros };
    });

  const TransformarDados = (lista) => {
    const newLista = CombinarDesligados(lista);

    // Obter todas as categorias únicas
    const categorias = new Set();
    newLista.forEach((item) => {
      item.contagem.forEach((c) => categorias.add(c.texto));
    });

    // Construir os datasets no formato desejado
    const datasets = Array.from(categorias).map((categoria) => ({
      name: categoria,
      data: newLista.map((item) => {
        const contagem = item.contagem.find((c) => c.texto === categoria);
        return contagem ? contagem.valor : 0;
      })
    }));

    return datasets;
  };

  const FromataDatas = (teste) => {
    const datas = teste.split(" - ");
    return MaskUtil.applyMesAnoMask(datas[0]);
  };

  const Sortdata = (a, b) => {
    const dataA = new Date(a.mes.split(" - ")[0]);
    const dataB = new Date(b.mes.split(" - ")[0]);
    return dataA - dataB; // Ordem crescente
  };

  const AreListsEqual = (list1, list2) => {
    if (list1.length !== list2.length) return false;

    return list1.every((item1, index) => {
      const item2 = list2[index];
      return (
        item1.mes === item2.mes &&
        JSON.stringify(item1.contagem) === JSON.stringify(item2.contagem)
      );
    });
  };
  useEffect(() => {
    if (AreListsEqual(DataGrafico.sort(Sortdata), Data) === false) {
      const newDate = DataGrafico.sort(Sortdata);

      setData(TransformarDados(newDate));
      setLebels(newDate.map((item) => FromataDatas(item.mes)));
    }
  }, [DataGrafico]);

  return (
    <ApexCharts
      data={{
        type: "line",
        labels: Lebels,
        datasets: Data
      }}
    />
  );
};

GraficoHistoricoAdmissaoDesligado.propTypes = {
  DataGrafico: PropTypes.arrayOf(
    PropTypes.shape({
      mes: PropTypes,
      contagem: PropTypes.arrayOf(
        PropTypes.shape({
          texto: PropTypes.string,
          valor: PropTypes.number
        })
      )
    })
  ).isRequired
};

export default GraficoHistoricoAdmissaoDesligado;
