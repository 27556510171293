import { useState } from "react";

import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

const AprovarPortalButton = ({ inventarioId, onSuccess }) => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const notify = useNotification();
  const Requicicao = new Api();
  const [isApproving, setIsApproving] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false); // Controle do diálogo de confirmação
  const [inventarioDetalhes, setInventarioDetalhes] = useState(null); // Armazena os detalhes do inventário

  // Função para buscar os detalhes do inventário (Usando ObterInventarioParaAplicar)
  const obterDetalhesInventario = async () => {
    try {
      setLoading(true);
      const resposta = await Requicicao.Get({
        endpoint: `/EstoquePorEmpresa/ObterInventarioParaAplicar/${inventarioId}`, // Usando a prop diretamente
        config: auth.GetHeaders()
      });

      if (resposta.objetoResposta) {
        setInventarioDetalhes(resposta.objetoResposta); // Armazena os detalhes do inventário
        setShowConfirmDialog(true); // Exibe o diálogo de confirmação
      } else {
        notify({
          type: "warn",
          message: "Nenhum inventário disponível para aprovação."
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.mensagemAdicional ||
        "Erro ao obter detalhes do inventário.";
      notify({ type: "error", message: errorMessage });
    } finally {
      setLoading(false);
    }
  };

  // Função para aprovar o inventário após a confirmação
  const aprovarInventario = async () => {
    setIsApproving(true);
    setLoading(true);
    try {
      // Preparando o comando para aprovação com os detalhes do inventário
      const comandoInventariar = {
        inventarioId: inventarioDetalhes.id,
        dataCriacao: inventarioDetalhes.dataCriacao,
        estoquesPorEmpresas: inventarioDetalhes.produtos.map((produto) => ({
          dataCriacao: inventarioDetalhes.dataCriacao,
          produtoId: produto.produtoId,
          empresaId: inventarioDetalhes.empresaId,
          quantidade: produto.quantidade
        }))
      };

      await Requicicao.Patch({
        endpoint: `/EstoquePorEmpresa/ProcessarProdutosInventarioPortalFeitoPDV`,
        data: comandoInventariar, // Enviando os dados completos do inventário
        config: auth.GetHeaders()
      });

      notify({ type: "success", message: "Inventário aprovado com sucesso." });
      if (onSuccess) onSuccess(); // Atualiza a listagem após sucesso
    } catch (error) {
      const errorMessage =
        error.response?.data?.mensagemAdicional ||
        "Erro ao aprovar inventário.";
      notify({ type: "error", message: errorMessage });
    } finally {
      setIsApproving(false);
      setLoading(false);
      setShowConfirmDialog(false); // Fecha o modal
    }
  };

  // Exibe o diálogo de confirmação após obter os detalhes do inventário
  const showConfirm = async () => {
    await obterDetalhesInventario(); // Obtém os detalhes do inventário antes de aprovar
  };

  const onConfirm = () => {
    aprovarInventario(); // Aprova o inventário se confirmado
  };

  const onCancel = () => {
    setShowConfirmDialog(false); // Fecha o diálogo se o usuário cancelar
  };

  return (
    <>
      <Button
        label={isApproving ? "Aprovando..." : "Aprovar Portal"}
        icon="pi pi-check"
        className="p-button-success"
        onClick={showConfirm} // Chama a função para buscar detalhes e mostrar o modal
        disabled={isApproving}
        style={{ marginLeft: "5px" }}
      />

      {/* Diálogo de confirmação */}
      <ConfirmDialog
        visible={showConfirmDialog}
        onHide={onCancel}
        message="Tem certeza que deseja aprovar este inventário?"
        header="Confirmação"
        icon="pi pi-exclamation-triangle"
        accept={onConfirm} // Chama o método de aprovação
        reject={onCancel} // Cancela a ação
      />
    </>
  );
};

export default AprovarPortalButton;
