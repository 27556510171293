import { useState, useEffect } from "react";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";

import { format } from "date-fns";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";
import useQuery from "utils/useQuery";

import ModalAlocacao from "./modules/ModalAlocacao";
import ModalNovoAcesso from "./modules/ModalNovoAcesso";
import ModalOcorrencias from "./modules/ModalOcorrencias";
import ModalPermissoes from "./modules/ModalPermissoes";
import QrCode from "./modules/QrCode";

const ListaFuncionarioContratadosAlocados = () => {
  const query = useQuery();
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();
  const navigate = useNavigate();
  const location = useLocation();

  const { StatusFuncionario } = MaskEnum;
  const StateDatafilter = {
    global: { value: null, matchMode: "contains" },
    nome: {
      operator: "and",
      constraints: [{ value: null, matchMode: "startsWith" }]
    },
    cpf: {
      operator: "and",
      constraints: [{ value: null, matchMode: "startsWith" }]
    }
  };

  const [Lista, setLista] = useState([]);
  const [filters, setFilters] = useState(StateDatafilter);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [empresaId, setEmpresaId] = useState("");
  const [matriz, setMatriz] = useState("");

  const [ShowQRcode, setShowQRcode] = useState(false);

  const [ShowModalNovoAcesso, setShowModalNovoAcesso] = useState(false);
  const [DadosAcesso, setDadosAcesso] = useState(null);

  const [FuncionarioId, setFuncionarioId] = useState("");

  const [Nome, setNome] = useState("");

  const [ListaEmpresas, setListaEmpresas] = useState([]);
  const [ListaFucao, setListaFucao] = useState([]);
  const [ShowModalAlocado, setShowModalAlocado] = useState(false);

  const [ShowModalOcorrencia, setShowModalOcorrencia] = useState(false);
  const [ShowModalPermissoes, setShowModalPermissoes] = useState(false);

  const ShowQRDadosFuncionaro = (id) => {
    setFuncionarioId(id);
    setShowQRcode(true);
  };

  const IsContratados = () => {
    if (location.pathname.includes("contratados")) return true;
    if (location.pathname.includes("alocados")) return false;
    return null;
  };

  const DetermineEndpoint = () => {
    if (IsContratados() === true) {
      return empresaId
        ? `/Funcionarios/Contratados/${empresaId}`
        : `/Funcionarios/Contratados`;
    }

    if (IsContratados() === false) {
      return empresaId
        ? `/Funcionarios/Alocados/${empresaId}`
        : `/Funcionarios/Alocados`;
    }
    return "/Funcionarios";
  };

  const StateDataPage = async () => {
    try {
      setLoading(true);
      const endpoint = DetermineEndpoint(); // Determina qual endpoint chamar
      const resposta = await Requicicao.Get({
        endpoint,
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        setLista(
          resposta.objetoResposta.sort((a, b) => {
            // Pega a data de atualização ou data de criação, se a atualização for null
            const dataA = new Date(a.dataAtualizacao || a.dataCriacao);
            const dataB = new Date(b.dataAtualizacao || b.dataCriacao);

            // Ordena de forma decrescente (mais recente primeiro)
            return dataB - dataA;
          })
        );

        if (resposta.objetoResposta.length > 0)
          setNome(resposta.objetoResposta[0].empresaContratante);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const InitFilters = () => {
    setFilters(StateDatafilter);
    setGlobalFilterValue("");
  };

  const DesativarFuncionario = async (rowData) => {
    try {
      setLoading(true);
      await Requicicao.Delete({
        endpoint: "/Funcionarios",
        params: { id: rowData.id },
        config: auth.GetHeaders()
      });
      notify({
        type: "success",
        message: "Funcionário desativado."
      });
      StateDataPage();
    } catch (error) {
      console.error(error);
      notify({
        type: "error",
        message: "Erro ao desativar o funcionário."
      });
    } finally {
      setLoading(false);
    }
  };

  const Confirm = (event, rowData) => {
    confirmPopup({
      group: "headless",
      target: event.currentTarget,
      message: "Deseja realmente excluir este funcionário?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => DesativarFuncionario(rowData),
      reject: () => {},
      acceptLabel: "Sim",
      rejectLabel: "Não"
    });
  };

  const ReenviarConvite = async (rowData) => {
    try {
      setLoading(true);

      const resposta = await Requicicao.Post({
        endpoint: `/Funcionarios/ReenviarConvite?funcionarioId=${rowData.id}`,
        data: { funcionarioId: rowData.id },
        config: auth.GetHeaders()
      });

      if (resposta.codigoEstadoHttp === 200) {
        notify({
          type: "success",
          message: `Convite reenviado para ${rowData.nome}.`
        });
      } else {
        notify({
          type: "error",
          message: "Erro ao reenviar o convite."
        });
      }
    } catch (error) {
      console.error("Erro ao reenviar o convite:", error);
      notify({
        type: "error",
        message: "Erro ao reenviar o convite."
      });
    } finally {
      setLoading(false);
    }
  };

  const ConfirmReenviar = (event, rowData) => {
    confirmPopup({
      group: "headless",
      target: event.currentTarget,
      message: "Deseja reenviar o convite para este funcionário?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => ReenviarConvite(rowData),
      reject: () => {},
      acceptLabel: "Sim",
      rejectLabel: "Não"
    });
  };

  const ClearFilter = () => {
    InitFilters();
  };

  const onGlobalFilterChange = (e) => {
    try {
      const { value } = e.target;
      const thisFilters = { ...filters };

      thisFilters.global.value = value;

      setFilters(thisFilters);
      setGlobalFilterValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  const GetEmpresas = async () => {
    try {
      const data = await Requicicao.Get({
        endpoint: "/Funcionarios/ObterEmpresas",
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        setListaEmpresas(data.objetoResposta);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GetFucao = async () => {
    try {
      const response = await Requicicao.Get({
        endpoint: "/Funcionarios/Funcoes",
        config: auth.GetHeaders()
      });
      if (response.codigoEstadoHttp === 200) {
        const formattedFuncoes = response.objetoResposta.map((funcao) => ({
          label: funcao.descricao,
          id: funcao.id
        }));
        setListaFucao(formattedFuncoes);
      }
    } catch (error) {
      console.error("Erro ao carregar funções:", error);
    }
  };

  const StarteAlocado = async (id) => {
    if (ListaEmpresas.length === 0) await GetEmpresas();
    if (ListaFucao.length === 0) await GetFucao();
    setShowModalAlocado(true);
    setFuncionarioId(id);
  };

  const StarteOcorrencia = (id) => {
    setShowModalOcorrencia(true);
    setFuncionarioId(id);
  };

  const StartePermissoes = (data) => {
    if (data.contaId == null) {
      notify({
        type: "aviso",
        message: "Este funcionário não tem acesso"
      });
    } else {
      setShowModalPermissoes(true);
      setFuncionarioId(data.id);
    }
  };

  const ClosePermissoes = () => {
    setFuncionarioId(null);
    setShowModalPermissoes(false);
  };

  const CriaUser = (data) => {
    setDadosAcesso(data);
    setShowModalNovoAcesso(true);
  };

  useEffect(() => {
    if (query.get("filial") != null) {
      setEmpresaId(query.get("filial"));
      setMatriz(query.get("matriz"));
      onGlobalFilterChange({ target: { value: query.get("filtro") } });
    }
  }, [query]);

  useEffect(() => {
    if (auth.isAuthenticated() && empresaId !== "") {
      StateDataPage();
    }
  }, [auth, empresaId]);

  return (
    <>
      <ModalPermissoes
        Close={ClosePermissoes}
        FuncionarioId={FuncionarioId}
        ShowModalPermissoes={ShowModalPermissoes}
        setShowModalPermissoes={setShowModalPermissoes}
      />
      <ModalOcorrencias
        FuncionarioId={FuncionarioId}
        ShowModalOcorrencia={ShowModalOcorrencia}
        setShowModalOcorrencia={setShowModalOcorrencia}
      />
      <ModalAlocacao
        FuncionarioId={FuncionarioId}
        ShowModalAlocado={ShowModalAlocado}
        setShowModalAlocado={setShowModalAlocado}
        ListaEmpresas={ListaEmpresas}
        ListaFucao={ListaFucao}
      />
      <QrCode
        FuncionarioId={FuncionarioId}
        ShowQRcode={ShowQRcode}
        setShowQRcode={setShowQRcode}
      />
      <ModalNovoAcesso
        ShowModalNovoAcesso={ShowModalNovoAcesso}
        setShowModallNovoAcesso={setShowModalNovoAcesso}
        Matriz={matriz}
        dados={DadosAcesso}
      />
      <ConfirmPopup />
      <div className="card p-3">
        {Nome !== "" && <h1 className="fs-3 mb-2">{Nome}</h1>}
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex gap-3 acoes-funcionarios">
            {IsContratados() && (
              <>
                <Button
                  type="button"
                  className="btn btn-primary"
                  icon="pi pi-user-plus"
                  onClick={() =>
                    navigate(`/app/rh/funcionarios/new/${empresaId}`)
                  }
                  label="Criar Funcionário"
                />
                <Button
                  type="button"
                  className="btn btn-primary"
                  icon="pi pi-envelope"
                  iconPos="left"
                  onClick={() =>
                    navigate(`/app/rh/funcionarios/convite/${empresaId}`)
                  }
                  label="Criar Convite"
                />
              </>
            )}
          </div>
          <div className="d-flex align-items-center gap-3">
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Limpar filtro"
              outlined
              onClick={ClearFilter}
            />
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Pesquisa Global"
              />
            </span>
          </div>
        </div>
        <DataTable
          value={Lista}
          stripedRows
          paginator
          rows={20}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="Nenhum funcionário encontrado."
          globalFilterFields={[
            "nome",
            "cpf",
            "funcao",
            "empresaContratante",
            "empresaAlocacao"
          ]}
          filters={filters}
        >
          <Column field="nome" header="Nome" sortable />
          <Column field="cpf" header="CPF" sortable />
          <Column
            field="statusCadastro"
            header="Status de cadastro"
            sortable
            body={(row) =>
              MaskEnum.StatusCadastroFuncionari[row.statusCadastro - 1]
            }
          />
          <Column
            field="status"
            header="Status"
            body={(rowData) =>
              rowData.status != null
                ? StatusFuncionario.find((x) => x.value === rowData.status)
                    .label
                : ""
            }
          />
          <Column field="funcao" header="Função" sortable />
          <Column
            field="dataAdmissao"
            header="Data de Admissão"
            sortable
            body={({ dataAdmissao }) =>
              dataAdmissao && dataAdmissao !== "0001-01-01T00:00:00"
                ? format(new Date(dataAdmissao), "dd/MM/yyyy")
                : ""
            }
          />
          <Column
            header="Ações"
            body={(rowData) => (
              <div
                className="d-flex flex-row flex-wrap gap-2 acoes-funcionario"
                style={{ maxWidth: `${45 * 5}px` }}
              >
                <Button
                  className="btn btn-tabela"
                  type="button"
                  icon="pi pi-pencil"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() =>
                    navigate(`/app/rh/funcionarios/editar/${rowData.id}`)
                  }
                  tooltip="Editar Funcionário"
                  tooltipOptions={{ position: "bottom" }}
                />
                <Button
                  className="btn btn-tabela"
                  type="button"
                  icon="bi bi-file-earmark-arrow-up"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() =>
                    navigate({
                      pathname: "/app/rh/funcionarios/up-arquivo",
                      search: createSearchParams({
                        id: rowData.id,
                        contaId: rowData.contaId
                      }).toString()
                    })
                  }
                  tooltip="Documentos Funcionário"
                  tooltipOptions={{ position: "bottom" }}
                />
                <Button
                  className="btn btn-tabela"
                  type="button"
                  icon="pi pi-qrcode"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() => ShowQRDadosFuncionaro(rowData.id)}
                  tooltip="QRCode"
                  tooltipOptions={{ position: "bottom" }}
                />
                {rowData.statusCadastro !== 8 &&
                  rowData.statusCadastro !== 5 && (
                    <Button
                      className="btn btn-tabela"
                      type="button"
                      icon="pi pi-envelope"
                      style={{ fontSize: "0.8rem" }}
                      onClick={(event) => ConfirmReenviar(event, rowData)}
                      tooltip="Reenviar Convite"
                      tooltipOptions={{ position: "bottom" }}
                      disabled={
                        rowData.statusCadastro === 8 ||
                        rowData.statusCadastro === 5
                      }
                    />
                  )}
                <Button
                  className="btn btn-tabela"
                  type="button"
                  icon="pi pi-arrow-right-arrow-left"
                  tooltip="Alocação"
                  tooltipOptions={{ position: "bottom" }}
                  onClick={() => StarteAlocado(rowData.id)}
                />
                <Button
                  className="btn btn-tabela"
                  type="button"
                  icon="bi bi-journal-bookmark"
                  tooltip="Ocorrências"
                  tooltipOptions={{ position: "bottom" }}
                  onClick={() => StarteOcorrencia(rowData.id)}
                />
                {auth.VerificarAccess([10]) && (
                  <Button
                    className="btn btn-tabela"
                    icon="bi bi-shield-check"
                    tooltip="Permissões"
                    tooltipOptions={{ position: "bottom" }}
                    onClick={() => StartePermissoes(rowData)}
                  />
                )}
                <Button
                  className="btn btn-tabela"
                  type="button"
                  icon="pi pi-user"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() => {
                    if (
                      rowData.statusCadastro !== 5 &&
                      rowData.statusCadastro !== 8
                    ) {
                      notify({
                        type: "aviso",
                        message: "Processo de admissão não foi concluído"
                      });
                    } else {
                      CriaUser(rowData);
                    }
                  }}
                  tooltip="Criar acesso para o Funcionário"
                  tooltipOptions={{ position: "bottom" }}
                />
                <Button
                  className="btn btn-tabela"
                  type="button"
                  icon="pi pi-trash"
                  style={{ fontSize: "0.8rem" }}
                  onClick={(event) => Confirm(event, rowData)}
                  tooltip="Remover Funcionário"
                  tooltipOptions={{ position: "bottom" }}
                />
              </div>
            )}
          />
        </DataTable>
      </div>
    </>
  );
};

export default ListaFuncionarioContratadosAlocados;
