/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react";

import { locale, addLocale } from "primereact/api";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";

import ExportMonetaryToExcel from "components/ExportToExcel/ExportMonetaryToExcel";
import ptBrLocale from "components/Locale/locales";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";

addLocale("pt-BR", ptBrLocale);
locale("pt-BR");

const ListaVendasProdutos = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();

  // const TipoLista = {
  //   Todos: 0,
  //   Orcamentos: 1,
  //   PreVendas: 2,
  //   Vandas: 3
  // };

  const [resumoVendedor, setResumoVendedor] = useState([]);
  const [resumoTipoPagamento, setResumoTipoPagamento] = useState([]);
  const [vendedorVsPagamento, setVendedorVsPagamento] = useState([]);
  const [resumoRanking, setResumoRanking] = useState([]);
  const [tabelaVisivel, setTabelaVisivel] = useState("vendedor"); // Para controlar qual tabela mostrar

  const [Lista, setLista] = useState([]);
  const [ListaBase, setListaBase] = useState([]);
  const [filters, setFilters] = useState(null);
  const [codigosLojas, setCodigosLojas] = useState([]);
  const [codigoLojaSelecionado, setCodigoLojaSelecionado] = useState([]);

  const getPrimeiroDiaDoMes = () => {
    const hoje = new Date();
    return new Date(hoje.getFullYear(), hoje.getMonth(), 1)
      .toISOString()
      .split("T")[0];
  };

  const [dataInicio, setDataInicio] = useState(getPrimeiroDiaDoMes());
  const [dataFim, setDataFim] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [exibicao, setExibicao] = useState("detalhada");

  const StateDataPage = async () => {
    try {
      setLoading(true);
      const params = {
        dataInicio,
        dataFim
      };
      // Faça a chamada à API
      const resposta = await Requicicao.Get({
        endpoint: "/Venda/ObterTodosResumoDados",
        params: { ...params }, // Passa os parâmetros de data
        config: auth.GetHeaders()
      });

      if (resposta?.codigoEstadoHttp === 200) {
        const itens = resposta.objetoResposta.map((itemVenda) => ({
          id: itemVenda.id,
          cupomFiscalId: itemVenda.cupomFiscalId,
          valorTotalLiquido: itemVenda.produtos
            .map((itemProduto) => itemProduto.valorTotalLiquido)
            .reduce((t, v) => t + v, 0),
          itensProdutos: itemVenda.produtos,
          dataCriacao: itemVenda.dataCriacao,
          dataAtualizacao: itemVenda.dataAtualizacao,
          cancelada: itemVenda.cancelada,
          cupomFiscalEmitido: itemVenda.cupomFiscalEmitido,
          pago: itemVenda.pago,
          numeroVenda: itemVenda.numeroVenda,
          codigoLoja: itemVenda.codigoLoja, // Armazenar o código da loja
          nomeEmpresa: itemVenda.nomeEmpresa,
          nomeVendedor: itemVenda.nomeVendedor,
          meiosPagamentos: itemVenda.meiosPagamentos
            .filter((pagamento) => pagamento.ehPago === true)
            .map((pagamento) => ({
              formaPagamento: pagamento.formaPagamento,
              valorPago: pagamento.valorPago,
              troco: pagamento.troco
            }))
        }));

        const itensOrdenados = itens.sort(
          (a, b) => new Date(b.dataCriacao) - new Date(a.dataCriacao)
        );

        // Obter lista de códigos de lojas únicos
        const lojasUnicas = [...new Set(itens.map((item) => item.codigoLoja))];
        setCodigosLojas(lojasUnicas); // Atualizar o estado com os códigos das lojas

        setListaBase(itensOrdenados);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // const filtrarVendasPorData = (vendas) => {
  //   const dataInicioFormatada = new Date(dataInicio)
  //     .toISOString()
  //     .split("T")[0];
  //   const dataFimFormatada = new Date(dataFim).toISOString().split("T")[0];

  //   return vendas.filter((venda) => {
  //     const dataVenda = venda.dataCriacao.split("T")[0];
  //     return dataVenda >= dataInicioFormatada && dataVenda <= dataFimFormatada;
  //   });
  // };

  // const filtrarVendasPorCodigoLoja = (
  //   vendas,
  //   codigosSelecionados = codigoLojaSelecionado
  // ) => {
  //   // Se a lista de códigos estiver vazia, retorna todas as vendas sem filtragem por código de loja
  //   if (codigosSelecionados.length === 0) {
  //     return vendas;
  //   }

  //   // Retorna apenas as vendas cujos códigos de loja estão na lista de códigos selecionados
  //   return vendas.filter((venda) =>
  //     codigosSelecionados.includes(venda.codigoLoja)
  //   );
  // };

  const mostrarResumoVendedor = (verificarVisibilidade = true) => {
    const vendasFiltradas = Lista.filter((venda) => venda.pago);

    const resumo = vendasFiltradas.reduce((acc, venda) => {
      if (!acc[venda.nomeVendedor]) {
        acc[venda.nomeVendedor] = 0;
      }
      acc[venda.nomeVendedor] += venda.valorTotalLiquido;
      return acc;
    }, {});

    const totalVendas = Object.values(resumo).reduce(
      (acc, valor) => acc + valor,
      0
    );

    const resumoArray = Object.keys(resumo).map((vendedor) => ({
      vendedor,
      total: resumo[vendedor]
    }));

    // Adiciona a linha de total
    resumoArray.push({
      vendedor: "Total",
      total: totalVendas
    });

    setResumoVendedor(resumoArray);

    if (verificarVisibilidade !== false) {
      setTabelaVisivel("vendedor");
    }
  };

  const mostrarRankingProdutos = (verificarVisibilidade = true) => {
    const vendasFiltradas = Lista.filter((venda) => venda.pago);

    const rankingProdutos = vendasFiltradas.reduce((acc, venda) => {
      venda.itensProdutos.forEach((produto) => {
        const key = produto.produtoDescricao;
        if (!acc[key]) {
          acc[key] = {
            quantidade: 0,
            valorTotal: 0,
            grupoProdutoDescricao: produto.grupoProdutoDescricao
          };
        }
        acc[key].quantidade += produto.quantidadeProduto;
        acc[key].valorTotal += produto.valorTotalLiquido;
      });
      return acc;
    }, {});

    const produtosOrdenados = Object.entries(rankingProdutos)
      .map(([descricao, dados]) => ({
        descricao,
        quantidade: dados.quantidade,
        valorTotal: dados.valorTotal,
        grupoProdutoDescricao: dados.grupoProdutoDescricao // Ensure it is passed along
      }))
      .sort((a, b) => b.valorTotal - a.valorTotal); // Sort by total value to highlight most lucrative products

    setResumoRanking(produtosOrdenados);
    if (verificarVisibilidade !== false) {
      setTabelaVisivel("rankingProdutos");
    }
  };

  const mostrarResumoTipoPagamento = (verificarVisibilidade = true) => {
    const vendasFiltradas = Lista.filter((venda) => venda.pago);

    const resumo = vendasFiltradas.reduce((acc, venda) => {
      venda.meiosPagamentos.forEach((pagamento) => {
        if (!acc[pagamento.formaPagamento]) {
          acc[pagamento.formaPagamento] = 0;
        }
        acc[pagamento.formaPagamento] += pagamento.valorPago;
      });
      return acc;
    }, {});

    const totalPagamentos = Object.values(resumo).reduce(
      (acc, valor) => acc + valor,
      0
    );

    const resumoArray = Object.keys(resumo).map((formaPagamento) => ({
      formaPagamento,
      total: resumo[formaPagamento]
    }));

    // Adiciona a linha de total
    resumoArray.push({
      formaPagamento: "Total",
      total: totalPagamentos
    });

    setResumoTipoPagamento(resumoArray);
    if (verificarVisibilidade !== false) {
      setTabelaVisivel("tipoPagamento");
    }
  };

  const mostrarVendedorVsPagamento = (verificarVisibilidade = true) => {
    const vendasFiltradas = Lista.filter((venda) => venda.pago);

    const resumo = vendasFiltradas.reduce((acc, venda) => {
      if (!acc[venda.nomeVendedor]) {
        acc[venda.nomeVendedor] = {};
      }
      venda.meiosPagamentos.forEach((pagamento) => {
        if (!acc[venda.nomeVendedor][pagamento.formaPagamento]) {
          acc[venda.nomeVendedor][pagamento.formaPagamento] = 0;
        }
        acc[venda.nomeVendedor][pagamento.formaPagamento] +=
          pagamento.valorPago;
      });
      return acc;
    }, {});

    const resumoArray = Object.keys(resumo).map((vendedor) => {
      const pagamentos = resumo[vendedor];
      return {
        vendedor,
        ...pagamentos
      };
    });

    // Calcula o total para cada tipo de pagamento
    const totaisPagamento = resumoArray.reduce((acc, row) => {
      Object.keys(row).forEach((key) => {
        if (key !== "vendedor") {
          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] += row[key] || 0;
        }
      });
      return acc;
    }, {});

    // Adiciona a linha de total
    resumoArray.push({
      vendedor: "Total",
      ...totaisPagamento
    });

    setVendedorVsPagamento(resumoArray);
    if (verificarVisibilidade !== false) {
      setTabelaVisivel("vendedorVsPagamento");
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      StateDataPage(); // Chama o endpoint sempre que as datas de início ou fim mudarem
    }
  }, [auth, dataInicio, dataFim]); // Adiciona as variáveis dataInicio e dataFim no array de dependências

  const atualizarResumos = () => {
    mostrarResumoVendedor(false);
    mostrarResumoTipoPagamento(false);
    mostrarVendedorVsPagamento(false);
    mostrarRankingProdutos(false);
  };

  useEffect(() => {
    const listaFiltrada = ListaBase.filter((item) => {
      const filtroCodigoLoja =
        codigoLojaSelecionado.length === 0 ||
        codigoLojaSelecionado.includes(item.codigoLoja);
      const dataInicioFormatada = new Date(dataInicio)
        .toISOString()
        .split("T")[0];
      const dataFimFormatada = new Date(dataFim).toISOString().split("T")[0];
      const dataItem = item.dataCriacao.split("T")[0];
      const filtroData =
        dataItem >= dataInicioFormatada && dataItem <= dataFimFormatada;

      return filtroCodigoLoja && filtroData;
    });

    setLista(listaFiltrada);
  }, [dataInicio, dataFim, codigoLojaSelecionado, ListaBase]);

  useEffect(() => {
    atualizarResumos();
  }, [Lista]); // Adiciona dependência para Lista

  const getExportData = () => {
    // Função auxiliar para formatar números como strings com duas casas decimais e trocar "." por ","
    const formatNumber = (value) =>
      typeof value === "number" ? value.toFixed(2).replace(".", ",") : value;

    // Garantir que os códigos sejam strings
    const formatCodigo = (codigo) => (codigo ? String(codigo) : "");

    // Formata a lista de produtos de forma detalhada
    const formatProdutos = (produtos) =>
      Array.isArray(produtos)
        ? produtos
            .map(
              (produto) =>
                `${produto.produtoDescricao} (Quantidade: ${formatNumber(
                  produto.quantidadeProduto
                )}, Valor: R$ ${formatNumber(produto.valorTotalLiquido)})`
            )
            .join("; ")
        : "";

    // Formata os meios de pagamento
    const formatMeiosPagamento = (meiosPagamentos) =>
      Array.isArray(meiosPagamentos)
        ? meiosPagamentos
            .map(
              (pagamento) =>
                `${pagamento.formaPagamento} (Valor: R$ ${formatNumber(
                  pagamento.valorPago
                )}, Troco: R$ ${formatNumber(pagamento.troco)})`
            )
            .join("; ")
        : "";

    // Função para formatar a data no formato DD/MM/YYYY HH:MM:SS
    const formatDateTime = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };

    if (exibicao === "detalhada") {
      return Lista.map((item) => ({
        Código: formatCodigo(item.numeroVenda),
        "Código Loja": formatCodigo(item.codigoLoja),
        Vendedor: item.nomeVendedor,
        Produtos: formatProdutos(item.itensProdutos),
        "Meios de Pagamento": formatMeiosPagamento(item.meiosPagamentos),
        Total: `R$ ${formatNumber(item.valorTotalLiquido)}`,
        "Data da Criação": formatDateTime(item.dataCriacao), // Formata a data
        "Data de Atualização": formatDateTime(item.dataAtualizacao) // Formata a data de atualização se existir
      }));
    }

    if (exibicao === "detalhadaProduto") {
      return Lista.flatMap((itemVenda) =>
        itemVenda.itensProdutos.map((produto) => ({
          "Número Venda": formatCodigo(itemVenda.numeroVenda),
          "Código Loja": formatCodigo(itemVenda.codigoLoja),
          Vendedor: itemVenda.nomeVendedor,
          Produto: produto.produtoDescricao,
          Quantidade: formatNumber(produto.quantidadeProduto),
          Valor: `R$ ${formatNumber(produto.valorTotalLiquido)}`,
          "Data da Criação": formatDateTime(itemVenda.dataCriacao),
          "Data de Atualização": formatDateTime(itemVenda.dataAtualizacao)
        }))
      );
    }

    if (exibicao === "resumo") {
      if (tabelaVisivel === "vendedor") {
        return resumoVendedor.map((item) => ({
          Vendedor: item.vendedor,
          Total: `R$ ${formatNumber(item.total)}`
        }));
      }

      if (tabelaVisivel === "tipoPagamento") {
        return resumoTipoPagamento.map((item) => ({
          "Forma de Pagamento": item.formaPagamento,
          Total: `R$ ${formatNumber(item.total)}`
        }));
      }

      if (tabelaVisivel === "vendedorVsPagamento") {
        return vendedorVsPagamento.map((item) => {
          const formattedItem = { Vendedor: item.vendedor };
          for (const key in item) {
            if (key !== "vendedor" && typeof item[key] === "number") {
              formattedItem[key] = `R$ ${formatNumber(item[key])}`;
            }
          }
          return formattedItem;
        });
      }

      if (exibicao === "resumo" && tabelaVisivel === "rankingProdutos") {
        return resumoRanking.map((item) => ({
          Produto: item.descricao,
          "Grupo do Produto": item.grupoProdutoDescricao,
          Quantidade: formatNumber(item.quantidade),
          Total: `R$ ${formatNumber(item.valorTotal)}`
        }));
      }
    }

    return [];
  };

  const getFileName = () => {
    if (exibicao === "detalhada") {
      return "Vendas Detalhadas";
    }
    if (exibicao === "detalhadaProduto") {
      return "Vendas Produtos";
    }
    if (exibicao === "resumo" && tabelaVisivel === "vendedor") {
      return "Resumo Vendedores";
    }
    if (exibicao === "resumo" && tabelaVisivel === "tipoPagamento") {
      return "Resumo Tipo Pagamento";
    }
    if (exibicao === "resumo" && tabelaVisivel === "vendedorVsPagamento") {
      return "Resumo Vendedor Vs Tipo Pagamento";
    }
    if (exibicao === "resumo" && tabelaVisivel === "rankingProdutos") {
      return "Ranking Produtos";
    }
    return "Relatorio"; // Nome padrão se nenhuma condição for atendida
  };

  return (
    <div className="card p-3">
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex gap-3">
          <Button
            label="Venda Detalhada Consolidada"
            icon="pi pi-list"
            onClick={() => setExibicao("detalhada")}
            className={exibicao === "detalhada" ? "p-button-primary" : ""}
          />
          <Button
            label="Venda Detalhada por Produto"
            icon="pi pi-box"
            onClick={() => setExibicao("detalhadaProduto")}
            className={
              exibicao === "detalhadaProduto" ? "p-button-primary" : ""
            }
          />
          <Button
            label="Resumo Vendas"
            icon="pi pi-chart-bar"
            onClick={() => {
              setExibicao("resumo");
              setTabelaVisivel("vendedor"); // Define diretamente para mostrar o resumo por vendedor
            }}
            className={
              exibicao === "resumo" && tabelaVisivel === "vendedor"
                ? "p-button-primary"
                : ""
            }
          />
        </div>
        <div className="d-flex gap-3">
          <div className="d-flex flex-row align-items-center gap-3">
            <div className="d-flex align-items-center">
              <label htmlFor="dataInicio" className="me-2">
                Data Início:
              </label>
              <InputText
                id="dataInicio"
                type="date"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </div>
            <div className="d-flex align-items-center ms-3">
              <label htmlFor="dataFim" className="me-2">
                Data Fim:
              </label>
              <InputText
                id="dataFim"
                type="date"
                value={dataFim}
                onChange={(e) => setDataFim(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <ExportMonetaryToExcel
            data={getExportData()}
            fileName={getFileName()}
          />
        </div>
        <div>
          <label htmlFor="dataFim" className="me-2">
            Lojas:
          </label>
          <MultiSelect
            value={codigoLojaSelecionado}
            options={codigosLojas.map((codigo) => ({
              label: codigo,
              value: codigo
            }))}
            onChange={(e) => setCodigoLojaSelecionado(e.value)}
            placeholder="Selecione os Códigos das Lojas"
            display="chip"
            style={{ width: "auto" }}
          />
        </div>
      </div>

      {exibicao === "detalhada" ? (
        <DataTable
          value={Lista}
          stripedRows
          paginator
          rows={20}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="Nenhum produto encontrado."
          filters={filters}
        >
          <Column
            field="id"
            header="Código"
            body={(rowData) => (
              <div title={rowData.id}>
                {" "}
                {/* Mostra o GUID ao passar o mouse */}
                {`${rowData.numeroVenda.toString()}`}{" "}
                {/* Exibe o número da venda */}
              </div>
            )}
          />
          <Column
            field="codigoLoja"
            header="Código Loja"
            body={(rowData) => (
              <div title={rowData.codigoLoja}>{rowData.codigoLoja}</div>
            )}
          />
          <Column
            field="nomeVendedor" // Certifique-se de que esse campo está no objeto rowData
            header="Vendedor"
            body={(rowData) => rowData.nomeVendedor} // Exibe o nome do vendedor
          />
          <Column
            field="produtos"
            header="Produtos"
            body={(rowData) => (
              <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                {Array.isArray(rowData.itensProdutos) &&
                  rowData.itensProdutos.map((produto, index) => (
                    <li key={produto.id || index}>
                      {produto.produtoDescricao} - Quantidade:{" "}
                      {produto.quantidadeProduto} - R${" "}
                      {produto.valorTotalLiquido.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      })}
                    </li>
                  ))}
              </ul>
            )}
          />
          <Column
            header="Meios de Pagamento"
            body={(rowData) => {
              // Calcula o troco total somando todos os trocos existentes
              const totalTroco = rowData.meiosPagamentos
                .filter((pagamento) => pagamento.troco > 0)
                .reduce((acc, pagamento) => acc + pagamento.troco, 0);

              return (
                <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                  {rowData.meiosPagamentos.map((pagamento) => (
                    <li
                      key={`${pagamento.formaPagamento}-${
                        pagamento.valorPago
                      }-${new Date(pagamento.data).getTime()}`}
                    >
                      {pagamento.formaPagamento} - R${" "}
                      {pagamento.valorPago.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      })}
                    </li>
                  ))}

                  {/* Exibe o troco total no final, caso exista */}
                  {totalTroco > 0 && (
                    <li>
                      <b>Troco:</b> R${" "}
                      {totalTroco.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      })}
                    </li>
                  )}
                </ul>
              );
            }}
          />
          <Column
            field="valorTotalLiquido"
            header="Total"
            body={(rowData) =>
              `R$ ${rowData.valorTotalLiquido.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL"
              })}`
            }
          />
          <Column
            field="dataCriacao"
            header="Data"
            body={(rowData) =>
              MaskUtil.applyDataAndHoraMask(rowData.dataCriacao)
            }
          />
          <Column
            header="Pago"
            body={(rowData) =>
              rowData.pago ? (
                <i
                  className="pi pi-check-circle"
                  style={{ color: "green" }}
                  title="Pago"
                />
              ) : (
                <i
                  className="pi pi-times"
                  style={{ color: "red" }}
                  title="Não pago ou cancelado"
                />
              )
            }
          />
          <Column
            body={(rowData) =>
              rowData.cupomFiscalEmitido === true ? (
                <i
                  className="pi pi-check-circle"
                  style={{ color: "green" }}
                  title="Emitido"
                />
              ) : (
                <i
                  className="pi pi-times"
                  style={{ color: "red" }}
                  title="Não emitido ou cancelado"
                />
              )
            }
            header="CFe"
          />
          <Column
            header="Status"
            body={(rowData) => {
              if (rowData.cancelada) {
                return <span style={{ color: "red" }}>Cancelada</span>;
              }
              if (rowData.pago) {
                return <span style={{ color: "green" }}>Finalizada</span>;
              }
              return <span style={{ color: "gray" }}>Não finalizada</span>;
            }}
          />
        </DataTable>
      ) : exibicao === "detalhadaProduto" ? (
        <DataTable
          value={Lista.flatMap((itemVenda) =>
            itemVenda.itensProdutos.map((produto) => ({
              ...produto,
              numeroVenda: itemVenda.numeroVenda,
              codigoLoja: itemVenda.codigoLoja,
              nomeVendedor: itemVenda.nomeVendedor,
              dataCriacao: itemVenda.dataCriacao
            }))
          )}
          stripedRows
          paginator
          rows={20}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="Nenhum produto encontrado."
        >
          <Column field="numeroVenda" header="Número da Venda" />
          <Column field="codigoLoja" header="Código Loja" />
          <Column field="nomeVendedor" header="Vendedor" />
          <Column field="produtoDescricao" header="Produto" />
          <Column field="quantidadeProduto" header="Quantidade" />
          <Column
            field="valorTotalLiquido"
            header="Valor"
            body={(rowData) =>
              `R$ ${rowData.valorTotalLiquido.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL"
              })}`
            }
          />
          <Column
            field="dataCriacao"
            header="Data"
            body={(rowData) =>
              MaskUtil.applyDataAndHoraMask(rowData.dataCriacao)
            }
          />
        </DataTable>
      ) : (
        <div className="d-flex justify-content-between mb-3">
          <Card title="Resumos" className="w-100 mb-3">
            <div className="d-flex justify-content-between">
              <Button
                label="Resumo por Vendedor"
                icon="pi pi-users"
                className="p-button-info"
                onClick={mostrarResumoVendedor}
              />
              <Button
                label="Resumo por Tipo de Pagamento"
                icon="pi pi-credit-card"
                className="p-button-warning"
                onClick={mostrarResumoTipoPagamento}
              />
              <Button
                label="Vendedor vs Tipo de Pagamento"
                icon="pi pi-chart-bar"
                className="p-button-success"
                onClick={mostrarVendedorVsPagamento}
              />
              <Button
                label="Ranking Produtos"
                icon="pi pi-sort-amount-up-alt"
                onClick={mostrarRankingProdutos}
                className="p-button-help" // Essa classe precisa ser definida no seu CSS para alterar a cor conforme desejado
              />
            </div>
            <p className="mt-2">
              Resumo referente ao período{" "}
              {new Date(dataInicio).toLocaleDateString("pt-BR", {
                timeZone: "UTC"
              })}{" "}
              e{" "}
              {new Date(dataFim).toLocaleDateString("pt-BR", {
                timeZone: "UTC"
              })}
            </p>
            {tabelaVisivel === "vendedor" && (
              <DataTable
                value={resumoVendedor}
                paginator
                rows={10}
                className="mb-3 mt-3"
              >
                <Column
                  field="vendedor"
                  header="Vendedor"
                  body={(rowData) => (
                    <span
                      style={{
                        fontWeight:
                          rowData.vendedor === "Total" ? "bold" : "normal"
                      }}
                    >
                      {rowData.vendedor}
                    </span>
                  )}
                />
                <Column
                  field="total"
                  header="Total Vendido"
                  body={(rowData) => (
                    <span
                      style={{
                        fontWeight:
                          rowData.vendedor === "Total" ? "bold" : "normal"
                      }}
                    >
                      R${" "}
                      {rowData.total.toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </span>
                  )}
                />
              </DataTable>
            )}

            {tabelaVisivel === "tipoPagamento" && (
              <DataTable
                value={resumoTipoPagamento}
                paginator
                rows={10}
                className="mb-3 mt-3"
              >
                <Column
                  field="formaPagamento"
                  header="Forma de Pagamento"
                  body={(rowData) => (
                    <span
                      style={{
                        fontWeight:
                          rowData.formaPagamento === "Total" ? "bold" : "normal"
                      }}
                    >
                      {rowData.formaPagamento}
                    </span>
                  )}
                />
                <Column
                  field="total"
                  header="Total"
                  body={(rowData) => (
                    <span
                      style={{
                        fontWeight:
                          rowData.formaPagamento === "Total" ? "bold" : "normal"
                      }}
                    >
                      R${" "}
                      {rowData.total.toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </span>
                  )}
                />
              </DataTable>
            )}

            {tabelaVisivel === "vendedorVsPagamento" && (
              <DataTable
                value={vendedorVsPagamento}
                paginator
                rows={10}
                className="mb-3 mt-3"
              >
                <Column
                  field="vendedor"
                  header="Vendedor"
                  body={(rowData) => (
                    <span
                      style={{
                        fontWeight:
                          rowData.vendedor === "Total" ? "bold" : "normal"
                      }}
                    >
                      {rowData.vendedor}
                    </span>
                  )}
                />
                {Object.keys(vendedorVsPagamento[0] || {})
                  .filter((key) => key !== "vendedor")
                  .map((formaPagamento) => (
                    <Column
                      key={formaPagamento}
                      field={formaPagamento}
                      header={formaPagamento}
                      body={(rowData) => (
                        <span
                          style={{
                            fontWeight:
                              rowData.vendedor === "Total" ? "bold" : "normal"
                          }}
                        >
                          {(rowData[formaPagamento] || 0).toLocaleString(
                            "pt-BR",
                            { style: "currency", currency: "BRL" }
                          )}
                        </span>
                      )}
                    />
                  ))}
                <Column
                  header="Total"
                  body={(rowData) => {
                    const total = Object.keys(rowData).reduce((acc, key) => {
                      if (key !== "vendedor") {
                        const valor = rowData[key] || 0;
                        return acc + valor;
                      }
                      return acc;
                    }, 0);

                    return (
                      <span style={{ fontWeight: "bold" }}>
                        R${" "}
                        {total.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </span>
                    );
                  }}
                />
              </DataTable>
            )}

            {tabelaVisivel === "rankingProdutos" && (
              <DataTable
                value={resumoRanking}
                paginator
                rows={10}
                className="mb-3 mt-3"
              >
                <Column
                  field="grupoProdutoDescricao"
                  header="Grupo do Produto"
                  body={(rowData) => rowData.grupoProdutoDescricao}
                />
                <Column field="descricao" header="Produto" />
                <Column field="quantidade" header="Quantidade Vendida" />
                <Column
                  field="valorTotal"
                  header="Valor Total"
                  body={(rowData) =>
                    `R$ ${rowData.valorTotal.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}`
                  }
                />
              </DataTable>
            )}
          </Card>
        </div>
      )}
    </div>
  );
};

export default ListaVendasProdutos;
