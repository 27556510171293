/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";

import PDFPreview from "components/PDFPreview";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";
import MaskUtil from "utils/MaskUtil";
import useQuery from "utils/useQuery";

const DocumentoAnual = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const auth = useAuth();
  const notify = useNotification();
  const { setLoading } = useLoading();
  const Requicicao = new Api();
  const { TipoAnual } = MaskEnum;

  const [EmpresaId, setEmpresaId] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);

  const [Lista, setLista] = useState();

  const [NameSelectedImage, setNameSelectedImage] = useState("");
  const [ShowMoldaPdf, setShowMoldaPdf] = useState(false);
  const [PdfFile, setPdfFile] = useState(null);

  const [ShowMoldaMitivo, setShowMoldaMitivo] = useState(false);
  const [Motivo, setMotivo] = useState("");
  const [ReprovacaoId, setReprovacaoId] = useState(null);

  const HandleDownloadDocumento = async (id, nomeArquivo) => {
    try {
      setLoading(true);
      const response = await Requicicao.Get({
        endpoint: `/Empresa/DocumentoById`,
        params: { id, empreId: EmpresaId },
        config: {
          ...auth.GetHeaders(),
          responseType: "blob"
        }
      });

      const blob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nomeArquivo);
      document.body.appendChild(link);
      link.click();
      link.remove();
      return true;
    } catch (error) {
      console.error("Erro ao carregar a imagem:", error);
      notify({
        type: "error",
        message: "Erro ao carregar a imagem. Tente novamente mais tarde."
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const HandleShowDocumento = async (id, nomeArquivo) => {
    try {
      setLoading(true);
      const response = await Requicicao.Get({
        endpoint: `/Empresa/DocumentoById`,
        params: { id, empreId: EmpresaId },
        config: {
          ...auth.GetHeaders(),
          responseType: "blob"
        }
      });

      const blob = new Blob([response], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      setPdfFile(blobUrl);
      setShowMoldaPdf(true);
      setNameSelectedImage(nomeArquivo);

      return true;
    } catch (error) {
      console.error("Erro ao carregar a imagem:", error);
      notify({
        type: "error",
        message: "Erro ao carregar a imagem. Tente novamente mais tarde."
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const AgruparDocumentosPorData = (docs) => {
    const agrupados = {};

    docs.forEach((doc) => {
      const data = new Date(doc.dataRelacao);
      const ano = data.getFullYear();

      if (!agrupados[ano]) {
        agrupados[ano] = [];
      }
      agrupados[ano].push(doc);
    });

    // Converte o objeto agrupado para uma lista, ordenando do mais novo para o mais antigo
    return Object.keys(agrupados)
      .sort((a, b) => b - a) // Agora é apenas uma comparação numérica
      .map((ano, i) => ({ id: i, data: ano, docs: agrupados[ano] }));
  };

  const StateData = async (id) => {
    try {
      setLoading(true);
      const data = await Requicicao.Get({
        endpoint: "/Empresa/DocumentoEmpresa",
        params: { id: id ?? EmpresaId, tipo: 1 }
      });
      setLista(AgruparDocumentosPorData(data.objetoResposta));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const ExpandAll = () => {
    try {
      const thisExpandedRows = {};

      // eslint-disable-next-line no-return-assign
      Lista.forEach((p) => (thisExpandedRows[p.id] = true));

      setExpandedRows(thisExpandedRows);
    } catch (error) {
      console.error(error);
    }
  };

  const RemoveDoc = async (id) => {
    try {
      setLoading(true);
      const data = await Requicicao.Delete({
        endpoint: "/Empresa/DocumentoById",
        params: { id },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        await StateData();
        notify({
          type: "sucesso",
          message: "Documento removido com sucesso"
        });
      } else {
        notify({
          type: "erro",
          message: "Erro ao remover o documento"
        });
      }
    } catch (error) {
      console.error(error);
      notify({
        type: "erro",
        message: "Erro ao remover o documento"
      });
    } finally {
      setLoading(false);
    }
  };

  const AprovadoRH = async (id, aprovado) => {
    try {
      setLoading(true);
      const data = await Requicicao.Put({
        endpoint: "/Empresa/DocumentoEmpresa/AprovadoRH",
        data: { id, aprovado },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        await StateData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const AprovadoMaster = async (id, aprovado, motivo = null) => {
    try {
      setLoading(true);
      const data = await Requicicao.Put({
        endpoint: "/Empresa/DocumentoEmpresa/AprovadoMaster",
        data: { id, aprovado, MotivoRecusa: motivo },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        await StateData();
      }
      setShowMoldaMitivo(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const ConfirmRemove = (id) => {
    confirmDialog({
      message: "Você tem certeza de que deseja remover?",
      header: "Confirmação de Remoção",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: () => RemoveDoc(id), // Executa a remoção se o usuário aceitar
      reject: () => {} // Opcional, para lidar com a rejeição
    });
  };

  const ConfirmAprovaRH = (id) => {
    confirmDialog({
      message: "Você tem certeza de que deseja aprovar?",
      header: "Confirmação de aprovação",
      icon: "pi pi-info-circle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: () => AprovadoRH(id, true),
      reject: () => {}
    });
  };

  const ConfirmReprovarRH = (id) => {
    confirmDialog({
      message: "Você tem certeza de que deseja reprovar?",
      header: "Confirmação de reprovação",
      icon: "pi pi-info-circle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: () => AprovadoRH(id, false),
      reject: () => {}
    });
  };

  const ConfirmAprovaMaster = (id) => {
    confirmDialog({
      message: "Você tem certeza de que deseja aprovar?",
      header: "Confirmação de aprovação",
      icon: "pi pi-info-circle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: () => AprovadoMaster(id, true),
      reject: () => {}
    });
  };

  const ConfirmReprovarMaster = (id) => {
    confirmDialog({
      message: "Você tem certeza de que deseja reprovar?",
      header: "Confirmação de reprovação",
      icon: "pi pi-info-circle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: () => {
        setShowMoldaMitivo(true);
        setReprovacaoId(id);
      },
      reject: () => {}
    });
  };

  useEffect(() => {
    const id = query.get("id");
    if (id != null && auth.isAuthenticated()) {
      setEmpresaId(id);
      StateData(id);
    }
  }, [query, auth]);

  const rowExpansionTemplate = (data) => (
    <DataTable value={data.docs} className="w-100 pt-3 pb-3 pe-2 ps-2">
      <Column field="nome" header="Documentos" />
      <Column field="descricao" header="Descrição" />
      <Column
        field="tipoAnual"
        header="Tipo"
        body={(row) => TipoAnual.find((x) => x.value === row.tipoAnual)?.label}
      />
      <Column
        field="aprovadoRH"
        header="Aprovado pelo RH"
        body={(row) =>
          row.aprovadoRH != null
            ? row.aprovadoRH
              ? "Sim"
              : "Não"
            : "Aguardando aprovação"
        }
      />
      <Column
        field="aprovadoMaster"
        header="Aprovado pela Master"
        body={(row) =>
          row.aprovadoMaster != null
            ? row.aprovadoMaster
              ? "Sim"
              : "Não"
            : "Aguardando aprovação"
        }
      />
      <Column
        field="dataCriacao"
        header="Data de criação"
        body={(row) => MaskUtil.applyDataMask(row.dataCriacao)}
      />
      <Column
        header="Ação"
        body={(row) => (
          <div className="d-flex flex-wrap gap-3">
            <Button
              label="Visualiza"
              icon="pi pi-eye"
              onClick={() => HandleShowDocumento(row.id, row.nome)}
            />
            <Button
              label="Baixar"
              icon="pi pi-download"
              onClick={() => HandleDownloadDocumento(row.id, row.nome)}
            />
            <Button
              label="Remover"
              severity="danger"
              icon="pi pi-file-excel"
              onClick={() => ConfirmRemove(row.id)}
            />
            {auth.VerificarAccess([60]) && (
              <>
                <Button
                  label="Reprovado"
                  severity="danger"
                  icon="pi pi-times"
                  disabled={row.aprovadoRH}
                  onClick={() => ConfirmReprovarRH(row.id)}
                />
                <Button
                  label="Aprovar"
                  severity="success"
                  icon="pi pi-check"
                  disabled={row.aprovadoRH}
                  onClick={() => ConfirmAprovaRH(row.id)}
                />
              </>
            )}
            {auth.VerificarAccess([10, 80]) && (
              <>
                <Button
                  label="Reprovado"
                  severity="danger"
                  icon="pi pi-times"
                  disabled={
                    row.aprovadoMaster ||
                    row.aprovadoRH === false ||
                    row.aprovadoRH == null
                  }
                  onClick={() => ConfirmReprovarMaster(row.id)}
                  tooltip="Enter your username"
                  tooltipOptions={{ position: "bottom" }}
                />
                <Button
                  label="Aprovar"
                  severity="success"
                  icon="pi pi-check"
                  disabled={
                    row.aprovadoMaster ||
                    row.aprovadoRH === false ||
                    row.aprovadoRH == null
                  }
                  onClick={() => ConfirmAprovaMaster(row.id)}
                />
              </>
            )}
          </div>
        )}
      />
    </DataTable>
  );

  return (
    <div className="card p-3">
      <ConfirmDialog />
      <Dialog
        header={NameSelectedImage}
        visible={ShowMoldaPdf}
        onHide={() => setShowMoldaPdf(false)}
      >
        <PDFPreview file={PdfFile} />
      </Dialog>
      <Dialog
        header="Motivo de reprovacao"
        visible={ShowMoldaMitivo}
        onHide={() => setShowMoldaMitivo(false)}
      >
        <form className="row g-3">
          <div>
            <label>Motivo</label>
            <InputTextarea
              value={Motivo}
              onChange={(e) => setMotivo(e.target.value)}
              rows={5}
              cols={30}
              className="w-100"
            />
          </div>
          <div className="d-flex justify-content-end gap-3">
            <Button
              label="Canselar"
              className="btn"
              type="button"
              onClick={() => setShowMoldaMitivo(false)}
            />
            <Button
              label="Reprovar"
              className="btn btn-primary"
              type="button"
              onClick={() => AprovadoMaster(ReprovacaoId, false, Motivo)}
            />
          </div>
        </form>
      </Dialog>
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex flex-row flex-wrap gap-2">
          <Button
            type="button"
            className="btn"
            label="Volta"
            onClick={() => navigate(-1)}
          />
          <Button
            label="Novo documento anual"
            className="btn btn-primary"
            onClick={() =>
              navigate({
                pathname: "/app/rh/documento/anual/new",
                search: createSearchParams({
                  id: EmpresaId,
                  tipo: "1"
                }).toString()
              })
            }
          />
        </div>
        <div className="flex flex-wrap justify-content-end gap-2">
          <Button
            icon="pi pi-plus"
            label="Expandir tudo"
            onClick={ExpandAll}
            text
          />
          <Button
            icon="pi pi-minus"
            label="Recolher tudo"
            onClick={() => setExpandedRows(null)}
            text
          />
        </div>
      </div>
      <DataTable
        value={Lista}
        dataKey="id"
        paginator
        rows={10}
        stripedRows
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column expander style={{ width: "3em" }} />
        <Column field="data" header="Ano" />
      </DataTable>
    </div>
  );
};

export default DocumentoAnual;
