import { createContext, useContext, useState, useEffect, useMemo } from "react";

import Api from "utils/Api";

const SystemVersionContext = createContext();

// eslint-disable-next-line react/prop-types
export const SystemVersionProvider = ({ children }) => {
  const [versaoSistema, setVersaoSistema] = useState("0");
  const Requicicao = new Api();

  useEffect(() => {
    const getVersaoSistema = async () => {
      try {
        const data = await Requicicao.Get({
          endpoint: "/ConfiguracaoSistema/Obter",
          params: { configuracao: 1 }
        });

        if (data.configuracao === "VersaoSistema") {
          setVersaoSistema(data.valor);
        }
      } catch (error) {
        console.error("Erro ao buscar versão do sistema:", error);
      }
    };
    getVersaoSistema();
  }, []);

  const Values = useMemo(() => versaoSistema, [versaoSistema]);

  return (
    <SystemVersionContext.Provider value={Values}>
      {children}
    </SystemVersionContext.Provider>
  );
};

export const useSystemVersion = () => {
  const context = useContext(SystemVersionContext);

  if (!context) {
    throw new Error(
      "useSystemVersion deve ser utilizado dentro de um SystemVersionProvider"
    );
  }
  return context;
};
