import { useState, useEffect } from "react";

import { format } from "date-fns";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog"; // Componente do modal
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { TriStateCheckbox } from "primereact/tristatecheckbox";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

import AprovarPortalButton from "./aprovarPortalButton";

const generateUniqueId = () =>
  Math.random().toString(36).substr(2, 9) + Date.now().toString(36);

const FazerInventario = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [Lista, setLista] = useState([]);
  const [ListaUpdata, setListaUpdata] = useState([]);

  const [ListaEmpresas, setListaEmpresas] = useState([]);
  const [SelectedEstoque, setSelectedEstoque] = useState(null);
  const [CodigoProduto, setCodigoProduto] = useState("");
  const [Automatica, setAutomatica] = useState(false);

  const [MudarEmpresa, setMudarEmpresa] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);

  const [isNewInventory, setIsNewInventory] = useState(false); // Novo estado para controlar a tela

  // Dados fake de inventários anteriores
  const [inventarios, setInventarios] = useState([]);
  const [selectedInventario, setSelectedInventario] = useState(null); // Estado para armazenar o inventário selecionado
  const [showModal, setShowModal] = useState(false); // Estado para controlar o modal

  const handleViewDetails = (inventario) => {
    setSelectedInventario(inventario); // Define o inventário selecionado
    setShowModal(true); // Abre o modal
  };

  // Modal de detalhes do inventário
  const renderModalContent = () => {
    if (!selectedInventario) return null;

    return (
      <div>
        <h5>Detalhes do Inventário</h5>
        <DataTable value={selectedInventario.produtos} paginator rows={5}>
          <Column field="produtoNome" header="Nome do Produto" />
          <Column field="codigoProduto" header="Código do Produto" />
          <Column field="quantidade" header="Quantidade" />
        </DataTable>
      </div>
    );
  };

  const GetAllEmpresas = async () => {
    try {
      const resposta = await Requicicao.Get({
        endpoint: "/Empresa/ObterFiliais",
        config: auth.GetHeaders()
      });
      setListaEmpresas(
        resposta.objetoResposta.map((item) => ({
          code: item.id,
          name: `${item.codigoLojaQueEstaInserida} - ${item.apelidoOuNomeFantasia} ${item.cpfOuCnpj}`
        }))
      );
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao obter a lista de empresas."
      });
      console.error(error);
    }
  };

  const StateDataPage = async () => {
    await GetAllEmpresas();
  };

  const OnGlobalFilterChange = (e) => {
    try {
      const { value } = e.target;
      const thisFilters = { ...filters };
      thisFilters.global.value = value;
      setFilters(thisFilters);
      setGlobalFilterValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  const InitFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      produtoNome: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      }
    });
    setGlobalFilterValue("");
  };

  const ClearFilter = () => {
    InitFilters();
  };

  const CarregarInventariosAnteriores = async () => {
    try {
      const resposta = await Requicicao.Get({
        endpoint: "/EstoquePorEmpresa/ObterInventarios", // Supondo que este seja o endpoint para obter os inventários
        config: auth.GetHeaders()
      });

      setInventarios(resposta.objetoResposta); // Atualiza a lista de inventários anteriores
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao carregar os inventários anteriores."
      });
      console.error(error);
    }
  };

  const actionBodyTemplate = (rowData) => {
    const recarregarInventarios = () => {
      CarregarInventariosAnteriores();
    };

    return (
      <div className="d-flex">
        <Button
          icon="pi pi-eye"
          label="Visualizar"
          onClick={() => handleViewDetails(rowData)}
          className="mr-2"
        />

        {rowData.status === "Enviado portal, aguardando aprovação" && (
          <AprovarPortalButton
            inventarioId={rowData.id}
            onSuccess={recarregarInventarios}
          />
        )}
      </div>
    );
  };

  const AplicarInventario = async () => {
    try {
      setLoading(true);
      const comandoInventariar = {
        estoquesPorEmpresas: ListaUpdata.map((item) => ({
          produtoId: item.id,
          empresaId: item.empresaId,
          quantidade: item.quantidade
        }))
      };

      await Requicicao.Patch({
        endpoint: "/EstoquePorEmpresa/CriarInventario",
        data: comandoInventariar,
        config: auth.GetHeaders()
      });

      notify({
        type: "success",
        message: "Inventário local criado com sucesso."
      });

      setLista([]);
      setListaUpdata([]);
      setCodigoProduto("");
      setSelectedEstoque(null);

      // Volta para a página inicial com a lista de inventários
      setIsNewInventory(false); // Alterna de volta para a lista de inventários
      await CarregarInventariosAnteriores(); // Recarrega os inventários existentes
    } catch (error) {
      notify({
        type: "error",
        message:
          "Erro ao tentar executar esta ação. Por favor, entre em contato com o suporte."
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const showConfirm = () => {
    confirmDialog({
      message: "Deseja concluir o processo de inventário?",
      header: "Confirmação de Conclusão",
      icon: "pi pi-exclamation-triangle",
      accept: () => AplicarInventario(),
      reject: () => console.log("Cancelado"),
      acceptLabel: "Sim",
      rejectLabel: "Não",
      style: { width: "450px" }
    });
  };

  const atualizarListaUpdata = (
    produto,
    lista,
    empresaId,
    isRemover = false
  ) => {
    const produtoExistenteIndex = lista.findIndex(
      (item) => item.sku === produto.sku
    );

    if (produtoExistenteIndex !== -1) {
      const novaLista = [...lista];

      if (isRemover) {
        novaLista[produtoExistenteIndex] = {
          ...novaLista[produtoExistenteIndex],
          quantidade: novaLista[produtoExistenteIndex].quantidade - 1
        };

        if (novaLista[produtoExistenteIndex].quantidade === 0)
          novaLista.splice(produtoExistenteIndex, 1);
      } else {
        novaLista[produtoExistenteIndex] = {
          ...novaLista[produtoExistenteIndex],
          quantidade: novaLista[produtoExistenteIndex].quantidade + 1
        };
      }

      return novaLista;
    }

    if (!isRemover) {
      return [
        ...lista,
        {
          ...produto,
          empresaId,
          quantidade: 1,
          data: format(new Date(), "dd/MM/yyyy HH:mm:ss")
        }
      ];
    }

    return lista;
  };

  const AdicionarItem = async () => {
    try {
      if (!CodigoProduto) {
        notify({
          type: "warning",
          message: "Informe o código ou SKU do produto"
        });
        return;
      }

      setLoading(true);
      const resposta = await Requicicao.Get({
        endpoint: `/Produto/BuscarPorCodigoOuSku?codigoOuSku=${CodigoProduto}`,
        config: auth.GetHeaders()
      });

      const produto = resposta.objetoResposta;

      if (produto) {
        const novoProduto = {
          ...produto,
          id: generateUniqueId(),
          data: format(new Date(), "dd/MM/yyyy HH:mm:ss")
        };

        setLista((lista) => [...lista, novoProduto]);
        setListaUpdata((lista) =>
          atualizarListaUpdata(produto, lista, SelectedEstoque.code)
        );

        setMudarEmpresa(false);
        setCodigoProduto("");
      } else {
        notify({ type: "warning", message: "Produto não encontrado." });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mensagem
      ) {
        notify({
          type: "warning",
          message: `${CodigoProduto}: ${
            error.response.data.mensagem || "Erro inesperado"
          }`
        });
      } else {
        notify({
          type: "error",
          message: error.message || "Erro ao buscar o produto. Tente novamente."
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const RemoverItem = (id) => {
    const produtoParaRemover = Lista.find((item) => item.id === id);
    if (!produtoParaRemover) return;

    setLista((prevLista) => prevLista.filter((item) => item.id !== id));
    setListaUpdata((prevListaUpdata) =>
      atualizarListaUpdata(
        produtoParaRemover,
        prevListaUpdata,
        SelectedEstoque.code,
        true
      )
    );
  };

  const processarCodigoProduto = async () => {
    if (CodigoProduto) {
      await AdicionarItem();
    }
    setCodigoProduto("");
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      StateDataPage();
      CarregarInventariosAnteriores(); // Carrega os inventários anteriores ao carregar o componente
    }
    InitFilters();
  }, [auth]);

  useEffect(() => {
    if (Automatica) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const id = setTimeout(() => {
        processarCodigoProduto();
      }, 500);

      setTimeoutId(id);
    }

    return () => clearTimeout(timeoutId);
  }, [CodigoProduto, Automatica]);

  const getTotalQuantidade = (produtos) =>
    produtos.reduce((total, produto) => total + produto.quantidade, 0);

  // Renderiza a lista de inventários anteriores ou a tela de novo inventário
  return isNewInventory ? (
    <div className="h-100 d-flex flex-column justify-content-end">
      <Button
        label="Voltar"
        icon="pi pi-arrow-left"
        onClick={() => setIsNewInventory(false)} // Volta para a listagem
        className="mb-3"
        style={{ width: "150px" }} // Ajusta a largura do botão diretamente
      />
      {/* Tela de inventário ativo */}
      <div className="row mb-3">
        <div className="col-6">
          <label htmlFor="estoque" className="form-label">
            Loja:
          </label>
          <Dropdown
            id="estoque"
            className="w-100"
            value={SelectedEstoque}
            onChange={(e) => {
              setSelectedEstoque(e.value);
            }}
            options={ListaEmpresas}
            optionLabel="name"
            placeholder="Selecione"
            disabled={!MudarEmpresa}
          />
        </div>
        <div className="col-6">
          <label>Inserção automática</label>
          <TriStateCheckbox
            className="w-100 mt-2"
            value={Automatica ? true : null}
            onChange={() => setAutomatica(!Automatica)}
          />
        </div>
        <div className="col-6 mt-3">
          <label htmlFor="produtoId" className="form-label">
            Código/SKU do produto
          </label>
          <InputText
            id="produtoId"
            className="w-100"
            value={CodigoProduto}
            onChange={(e) => setCodigoProduto(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                AdicionarItem();
              }
            }}
          />
        </div>
        <div className="col-3">
          <div className="h-100 d-flex flex-column justify-content-end">
            <Button
              type="button"
              label="Adicionar"
              icon="pi pi-plus"
              className="btn"
              onClick={AdicionarItem}
              disabled={SelectedEstoque === null}
            />
          </div>
        </div>
        <ConfirmDialog />
        <div className="col-3">
          <div className="h-100 d-flex flex-column justify-content-end">
            <Button
              type="button"
              label="Concluir Inventário"
              icon="pi pi-check"
              className="btn btn-primary"
              onClick={showConfirm}
              disabled={Lista.length === 0}
            />
          </div>
        </div>
      </div>

      {/* Tabela de produtos adicionados */}
      <div className="card p-3">
        <div className="d-flex justify-content-between mb-3">
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Limpar filtro"
            outlined
            onClick={ClearFilter}
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={OnGlobalFilterChange}
              placeholder="Pesquisa Global"
            />
          </span>
        </div>
        <h5>Inventário</h5>
        <DataTable
          value={Lista}
          stripedRows
          paginator
          rows={20}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="Nenhum produto."
          globalFilterFields={["descricao"]}
          filters={filters}
          sortField="data"
          sortOrder={-1}
          className="small-table"
        >
          <Column field="codigo" header="Código do produto" />
          <Column field="sku" header="SKU" />
          <Column field="descricao" header="Nome do produto" />
          <Column field="data" header="Data" sortable />
          <Column
            header="Ações"
            body={(rowData) => (
              <button
                type="button"
                onClick={() => RemoverItem(rowData.id)}
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  color: "black",
                  cursor: "pointer"
                }}
                aria-label="Remover item"
              >
                <i className="pi pi-trash" style={{ fontSize: "0.8rem" }} />
              </button>
            )}
          />
        </DataTable>

        {/* Tabela de inventário agrupado */}
        <div className="mt-3">
          <h5>Inventário Agrupado</h5>
          <DataTable
            value={ListaUpdata}
            stripedRows
            paginator
            rows={20}
            tableStyle={{ minWidth: "50rem" }}
            emptyMessage="Nenhum produto."
            sortField="data"
            sortOrder={-1}
            className="small-table"
          >
            <Column field="codigo" header="Código do produto" sortable />
            <Column field="sku" header="SKU" sortable />
            <Column field="descricao" header="Nome do produto" sortable />
            <Column field="quantidade" header="Quantidade" sortable />
          </DataTable>
        </div>
      </div>
    </div>
  ) : (
    <div className="card p-3">
      {/* Alinhando os botões lado a lado */}
      <div className="d-flex align-items-center">
        <Button
          label="Iniciar Novo Inventário"
          icon="pi pi-plus"
          onClick={() => {
            setIsNewInventory(true);
            setMudarEmpresa(true);
          }}
          className="ml-3"
          style={{ width: "200px", marginLeft: "10px" }} // Ajusta a largura do botão diretamente
        />
      </div>

      <div className="card p-3">
        <h5>Inventários Anteriores</h5>
        <DataTable
          value={inventarios}
          paginator
          rows={10}
          sortField="dataCriacao"
          sortOrder={-1} // -1 indica ordem decrescente
        >
          {/* Formatação da data para o formato dd/MM/yyyy */}
          <Column
            field="dataCriacao"
            header="Data"
            body={(rowData) =>
              format(new Date(rowData.dataCriacao), "dd/MM/yyyy hh:mm:ss")
            }
          />

          {/* Exibe o status do inventário com base no mapeamento */}
          <Column field="status" header="Status" />

          {/* Exibe o código da loja */}
          <Column field="codigoLoja" header="Código Loja" />

          {/* Soma da quantidade de produtos no inventário */}
          <Column
            field="produtos"
            header="Qtd. de Produtos"
            body={(rowData) => getTotalQuantidade(rowData.produtos)}
          />

          {/* Botão de ação para visualizar detalhes */}
          <Column body={actionBodyTemplate} header="Ações" />
        </DataTable>

        {/* Modal para mostrar os detalhes dos produtos de um inventário */}
        <Dialog
          header="Detalhes do Inventário"
          visible={showModal}
          style={{ width: "50vw" }}
          modal
          onHide={() => setShowModal(false)}
        >
          {renderModalContent()}
        </Dialog>
      </div>
    </div>
  );
};

export default FazerInventario;
