import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";

import CodigoEmBarras from "components/CodigoEmBarras";
import ExportToExcel from "components/ExportToExcel";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";

const ListaProduto = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const [Lista, setLista] = useState([]);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const StateDataPage = async () => {
    try {
      setLoading(true);
      const resposta = await Requicicao.Get({
        endpoint: "/Produto",
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        setLista(resposta.objetoResposta);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const prepareExportData = (data) =>
    data.map((item) => ({
      "Grupo Produto": item.grupoProduto || "",
      Descrição: item.descricao || "",
      Código: item.codigo || "",
      "Código GTIN/EAN": item.codigoGtinEan || "",
      SKU: item.sku || "",
      "Valor de Venda": item.valorVenda
        ? MaskUtil.applyMonetaryMask(item.valorVenda)
        : "",
      "Valor de Custo": item.valorCusto
        ? MaskUtil.applyMonetaryMask(item.valorCusto)
        : ""
    }));

  const InitFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      descricao: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      codigoGtinEan: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      sku: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      codigo: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      grupoProduto: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      }
    });
    setGlobalFilterValue("");
  };

  const ClearFilter = () => {
    InitFilters();
  };

  const OnGlobalFilterChange = (e) => {
    try {
      const { value } = e.target;
      const thisFilters = { ...filters };

      thisFilters.global.value = value;

      setFilters(thisFilters);
      setGlobalFilterValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  const DesativarProduto = async (rowData) => {
    try {
      setLoading(true);
      await Requicicao.Delete({
        endpoint: "/Produto",
        params: { id: rowData.id },
        config: auth.GetHeaders()
      });

      notify({
        type: "success",
        message: "Produto desativado."
      });
      StateDataPage();
    } catch (error) {
      console.error(error);
      notify({
        type: "error",
        message: "Erro ao desativar o produto."
      });
    } finally {
      setLoading(false);
    }
  };

  const Confirm = (event, rowData) => {
    confirmPopup({
      group: "headless",
      target: event.currentTarget,
      message: "Tem certeza que deseja deletar este item?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => {
        DesativarProduto(rowData);
      },
      reject: () => {},
      acceptLabel: "Sim",
      rejectLabel: "Não"
    });
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      StateDataPage();
    }
    InitFilters();
  }, [auth]);

  const [visibleRight, setVisibleRight] = useState(false);
  const [DataShowItem, setDataShowItem] = useState(null);

  return (
    <>
      <div className="card p-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex flex-row gap-3">
            <Link to="/app/produto/new" className="btn btn-primary">
              Criar
            </Link>
            <Link to="/app/produto/importar" className="btn btn-secondary">
              Importar Produtos
            </Link>
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Limpar filtro"
              outlined
              onClick={ClearFilter}
            />
            <ExportToExcel
              data={prepareExportData(Lista)}
              fileName="Produtos_Exportados"
            />
          </div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={OnGlobalFilterChange}
              placeholder="Pesquisa Global"
            />
          </span>
        </div>
        <DataTable
          value={Lista}
          stripedRows
          paginator
          rows={20}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="Nenhum produto encontrado."
          globalFilterFields={[
            "descricao",
            "codigo",
            "codigoGtinEan",
            "sku",
            "grupoProduto"
          ]}
          filters={filters}
        >
          <Column field="codigo" header="Código" sortable />
          <Column field="descricao" header="Descrição" sortable />
          <Column field="codigoGtinEan" header="Código GTIN/EAN" sortable />
          <Column field="sku" header="SKU" sortable />
          <Column field="grupoProduto" header="Grupo Produto" sortable />
          <Column
            field="valorVenda"
            header="Valor de Venda"
            body={(rowData) => MaskUtil.applyMonetaryMask(rowData.valorVenda)}
            sortable
          />
          <Column
            field="valorCusto"
            header="Valor de Custo"
            body={(rowData) => MaskUtil.applyMonetaryMask(rowData.valorCusto)}
            sortable
          />
          <Column
            header="Ações"
            body={(rowData) => (
              <div>
                <ConfirmPopup />
                <div className="d-flex flex-row gap-3">
                  <Link
                    to={`/app/produto/editar?id=${rowData.id}`}
                    className="btn btn-tabela"
                  >
                    <i className="bi bi-pencil" />
                  </Link>
                  <Button
                    className="btn btn-tabela"
                    type="button"
                    icon="pi pi-tag"
                    onClick={() => {
                      setDataShowItem(rowData);
                      setVisibleRight(true);
                    }}
                  />
                  <Button
                    className="btn btn-tabela"
                    type="button"
                    icon="bi bi-trash"
                    onClick={(event) => {
                      Confirm(event, rowData);
                    }}
                  />
                </div>
              </div>
            )}
          />
        </DataTable>
      </div>
      <Sidebar
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h2>Dados do Produto</h2>
        {DataShowItem?.codigo !== undefined && (
          <>
            <p>
              <strong>ID:</strong> {DataShowItem.id}
            </p>
            <p>
              <strong>Descrição:</strong> {DataShowItem.descricao}
            </p>
            <p>
              <strong>Código GTIN/EAN:</strong> {DataShowItem.codigoGtinEan}
            </p>
            <p>
              <strong>SKU:</strong> {DataShowItem.sku}
            </p>
            <p>
              <strong>Grupo Produto:</strong> {DataShowItem.grupoProduto}
            </p>
            <p>
              <strong>Valor de Venda:</strong> R${" "}
              {MaskUtil.applyMonetaryMask(DataShowItem.valorVenda)}
            </p>
            <p>
              <strong>Valor de Custo:</strong> R${" "}
              {MaskUtil.applyMonetaryMask(DataShowItem.valorCusto)}
            </p>
            <CodigoEmBarras barcodeData={DataShowItem.codigo} />
          </>
        )}
      </Sidebar>
    </>
  );
};

export default ListaProduto;
